<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="d-flex justify-content-center p-4">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="290px" viewBox="0 0 290 162" enable-background="new 0 0 290 162" xml:space="preserve" role="img">
            <g>
              <path fill="#9CD0E5" d="M122.195,5.363c21.258-8.651,46.13-0.338,57.659,20.202c2.281,4.066,3.877,8.331,4.834,12.66
                c4.022,11.022,3.138,22.974-1.864,33.068l10.255,2.879c6.599-14.682,6.398-32.192-2.086-47.306
                C177.295,2.461,147.021-6.764,122.195,5.363z"></path>
            </g>
            <g>
              <path fill="#0099DB" d="M178.856,51.127c-1.346,18.559-16.312,33.653-35.373,34.661c-3.772,0.202-7.443-0.169-10.932-1.027
                c-9.451-1.033-17.816-5.966-23.354-13.225l-5.774,6.42c8.297,10.067,21.092,16.207,35.117,15.466
                C161.19,92.222,178.754,73.526,178.856,51.127z"></path>
            </g>
            <path fill="#0062A7" d="M164.273,73.488c-16.048,9.413-36.963,5.641-48.6-9.494c-2.304-2.994-4.08-6.229-5.351-9.59
              c-4.508-8.374-5.189-18.063-2.35-26.742l-8.561-1.117c-3.586,12.544-1.388,26.568,7.174,37.704
              C120.415,82.23,145.77,86.104,164.273,73.488z"></path>
            <path fill="#00365C" d="M111.438,45.732c1.631,4.789,4.086,9.01,7.522,12.229c-2.104-2.591-3.736-5.632-4.727-9.038
              c-3.976-13.658,3.87-27.949,17.523-31.925c0.18-0.053,0.358-0.093,0.539-0.143c6.768-1.423,14.051,0.506,19.021,4.64l1.316-1.582
              l2.149-2.584c-6.688-5.558-15.927-7.805-24.905-5.19C115.512,16.323,106.305,30.647,111.438,45.732z"></path>
            <g>
              <path fill="#00365C" d="M57.864,138.229l1.091,2.054c-2.466,1.626-4.744,2.776-6.832,3.454c-2.088,0.677-4.273,1.016-6.554,1.016
                c-4.818,0-8.606-1.372-11.365-4.117s-4.137-6.441-4.137-11.09c0-4.934,1.554-8.904,4.661-11.913s7.405-4.513,12.894-4.513
                c1.753,0,3.446,0.139,5.078,0.417c1.632,0.277,3.653,0.795,6.062,1.55c-0.086,0.928-0.128,1.89-0.128,2.888
                c0,1.726,0.107,3.836,0.321,6.331h-2.352c-0.713-2.738-1.183-4.342-1.411-4.812c-0.528-1.07-1.394-1.976-2.598-2.717
                s-2.833-1.112-4.886-1.112c-3.122,0-5.531,0.955-7.227,2.865c-2.053,2.296-3.079,5.469-3.079,9.519
                c0,4.192,1.023,7.383,3.068,9.571s4.8,3.283,8.264,3.283C51.813,140.903,54.856,140.012,57.864,138.229z"></path>
              <path fill="#00365C" d="M61.734,115.344v-1.582c3.438,0.156,7.176,0.234,11.214,0.234c4.68,0,9.501-0.078,14.466-0.234
                c0.042,2.986,0.221,5.581,0.535,7.785h-1.82c-0.171-0.455-0.293-0.803-0.364-1.044c-0.7-2.03-1.249-3.209-1.649-3.535
                c-0.399-0.327-1.299-0.49-2.698-0.49h-8.18c-0.114,2.405-0.186,5.899-0.214,10.48l3.729,0.043c1.943,0,3.14-0.189,3.59-0.566
                c0.45-0.378,0.925-1.651,1.425-3.818h1.775c-0.157,2.096-0.235,3.942-0.235,5.539c0,1.526,0.079,3.43,0.235,5.711H81.77
                c-0.4-2.3-0.786-3.618-1.158-3.959s-1.493-0.511-3.365-0.511h-4.201v11.955h8.51c0.941,0,1.568-0.057,1.882-0.172
                c0.499-0.187,0.955-0.518,1.368-0.991c0.87-0.962,1.889-2.484,3.058-4.567h1.882c-0.827,2.349-1.718,5.18-2.673,8.49
                c-4.861-0.157-9.942-0.235-15.245-0.235c-2.409,0-5.773,0.078-10.093,0.235v-1.582c2.367-0.428,3.721-0.771,4.063-1.027
                c0.47-0.328,0.706-1.155,0.706-2.48v-0.729l0.042-8.469v-6.845l-0.021-3.165c0-1.526-0.078-2.475-0.235-2.845
                c-0.157-0.371-0.46-0.663-0.909-0.877C64.931,115.879,63.716,115.63,61.734,115.344z"></path>
              <path fill="#00365C" d="M90.9,115.344v-1.582c4.732,0.156,8.218,0.234,10.456,0.234c5.901,0,11.055-0.078,15.459-0.234
                c0.085,3.165,0.278,5.975,0.577,8.427h-1.753l-0.214-0.663c-0.599-1.939-1.147-3.28-1.646-4.021
                c-0.214-0.299-0.52-0.517-0.919-0.651c-0.399-0.136-1.19-0.204-2.373-0.204h-8.189v10.331h3.72c1.44,0,2.342-0.071,2.705-0.214
                s0.652-0.392,0.866-0.748c0.328-0.557,0.691-1.727,1.091-3.508h1.774c-0.171,2.167-0.256,4.228-0.256,6.18
                c0,1.783,0.085,3.559,0.256,5.326h-1.774c-0.471-2.096-0.798-3.351-0.984-3.764c-0.157-0.3-0.385-0.514-0.684-0.642
                c-0.385-0.157-1.34-0.235-2.865-0.235h-3.849v9.582c0,1.155,0.068,1.875,0.203,2.16s0.367,0.491,0.695,0.62
                c0.527,0.229,1.682,0.449,3.464,0.663c0.228,0.015,0.563,0.058,1.005,0.129v1.582c-3.521-0.157-6.479-0.235-8.874-0.235
                c-2.395,0-5.024,0.078-7.89,0.235v-1.582c2.452-0.428,3.838-0.771,4.159-1.027c0.321-0.257,0.51-0.728,0.566-1.411
                c0.034-0.514,0.063-4.413,0.085-11.7v-5.774v-1.754c0-2.125-0.053-3.38-0.16-3.765s-0.289-0.655-0.545-0.813
                c-0.328-0.199-1.411-0.463-3.25-0.792C91.57,115.465,91.285,115.415,90.9,115.344z"></path>
              <path fill="#00365C" d="M135.161,144.111v-1.582c1.724-0.314,2.722-0.572,2.993-0.772c0.27-0.2,0.406-0.472,0.406-0.815
                c0-0.199-0.043-0.429-0.128-0.687c-0.228-0.6-0.968-2.401-2.219-5.405c-2.006-0.042-3.678-0.063-5.015-0.063
                c-1.095,0-2.923,0.021-5.484,0.063c-1.599,3.748-2.398,5.799-2.398,6.156c0,0.344,0.114,0.597,0.342,0.762
                c0.228,0.164,1.24,0.418,3.036,0.762v1.582c-1.796-0.157-3.514-0.235-5.153-0.235c-1.768,0-3.563,0.078-5.388,0.235v-1.582
                c1.283-0.271,2.167-0.579,2.651-0.924c0.328-0.229,0.627-0.57,0.898-1.028c0.557-0.898,2.22-4.377,4.991-10.436
                c1.756-3.835,4.262-9.51,7.518-17.021h1.71c1.326,3.35,4.07,9.602,8.232,18.753c2.31,5.09,3.692,7.984,4.148,8.683
                c0.313,0.486,0.627,0.829,0.94,1.029s1.305,0.516,2.973,0.944v1.582c-2.523-0.157-4.868-0.235-7.035-0.235
                C141.041,143.876,138.368,143.954,135.161,144.111z M131.075,122.341l-4.372,10.071l4.236,0.021c1.013,0,2.467-0.007,4.364-0.021
                L131.075,122.341z"></path>
              <path fill="#0099DB" d="M186.798,127.45l-0.021-1.519l-0.021-4.599l-0.043-4c-0.015-0.599-0.1-0.969-0.257-1.111
                c-0.228-0.243-0.784-0.428-1.668-0.557l-2.887-0.643v-1.261c3.282,0.156,5.656,0.234,7.12,0.234c1.605,0,3.83-0.078,6.672-0.234
                v1.261c-2.342,0.485-3.662,0.793-3.959,0.921c-0.299,0.128-0.498,0.271-0.597,0.428c-0.156,0.257-0.241,1.04-0.256,2.352
                c-0.028,2.14-0.042,5.804-0.042,10.994l0.085,10.139v0.599c0,0.67,0.121,1.098,0.365,1.283c0.401,0.3,1.883,0.671,4.446,1.112
                v1.262c-3.919-0.157-6.406-0.235-7.465-0.235c-1.759,0-3.896,0.078-6.412,0.235v-1.262l3.931-0.877
                c0.242-0.1,0.429-0.243,0.558-0.428c0.201-0.3,0.308-0.863,0.322-1.689l0.107-10.053c-3.755-0.071-6.631-0.106-8.63-0.106
                c-2.155,0-4.432,0.035-6.83,0.106l0.064,10.33c0,0.741,0.071,1.212,0.213,1.412c0.142,0.199,0.511,0.386,1.106,0.557
                c0.143,0.043,1.307,0.292,3.491,0.748v1.262c-3.473-0.157-5.928-0.235-7.365-0.235c-1.778,0-3.936,0.078-6.469,0.235v-1.262
                c1.54-0.342,2.688-0.584,3.442-0.727c0.57-0.1,0.934-0.242,1.091-0.429c0.214-0.242,0.32-0.749,0.32-1.519
                c0.043-2.724,0.064-6.616,0.064-11.679l-0.106-10.587v-0.684c0-0.499-0.082-0.845-0.245-1.037s-0.507-0.354-1.033-0.481
                l-3.533-0.686v-1.261c2.884,0.156,5.29,0.234,7.217,0.234c2.027,0,4.248-0.078,6.66-0.234v1.261l-3.608,0.707
                c-0.458,0.114-0.773,0.274-0.945,0.481c-0.172,0.206-0.257,0.573-0.257,1.101l-0.107,10.139c2.201,0.042,4.524,0.063,6.968,0.063
                C181.444,127.514,184.282,127.492,186.798,127.45z"></path>
              <path fill="#0099DB" d="M231.979,128.477v1.177l-3.271,0.791c-0.342,0.086-0.592,0.21-0.748,0.374
                c-0.157,0.164-0.264,0.402-0.321,0.717c-0.113,0.642-0.171,2.246-0.171,4.813c0,1.539,0.058,3.629,0.171,6.267
                c-0.627,0.1-1.974,0.385-4.041,0.855c-2.708,0.599-4.419,0.941-5.132,1.026c-1.383,0.171-2.851,0.257-4.404,0.257
                c-5.018,0-8.874-1.329-11.567-3.989c-2.694-2.659-4.042-6.284-4.042-10.875c0-4.978,1.579-9.016,4.736-12.117
                s7.359-4.651,12.604-4.651c1.312,0,2.566,0.078,3.764,0.235c0.271,0.028,2.708,0.463,7.313,1.304
                c-0.015,0.784-0.021,1.412-0.021,1.883c0,1.91,0.093,4.07,0.278,6.48h-1.604c-0.656-2.438-1.272-4.042-1.85-4.813
                c-0.577-0.77-1.515-1.397-2.812-1.882c-1.711-0.655-3.564-0.984-5.56-0.984c-4.419,0-7.74,1.669-9.964,5.005
                c-1.554,2.354-2.331,5.248-2.331,8.685c0,4.392,1.229,7.721,3.688,9.988c2.459,2.267,5.563,3.4,9.312,3.4
                c2.366,0,4.854-0.499,7.463-1.497c0.057-1.54,0.085-3.8,0.085-6.78c0-1.611-0.042-2.56-0.128-2.845s-0.257-0.506-0.514-0.663
                c-0.327-0.199-1.297-0.463-2.907-0.791l-0.834-0.192v-1.177l7.313,0.149C228.094,128.626,229.926,128.576,231.979,128.477z"></path>
              <path fill="#0099DB" d="M242.349,127.15h5.175c1.995,0,3.172-0.054,3.528-0.16c0.356-0.107,0.645-0.324,0.865-0.652
                c0.222-0.328,0.496-1.276,0.823-2.845c0.043-0.157,0.107-0.399,0.192-0.727h1.411c-0.156,2.352-0.234,4.163-0.234,5.432
                c0,1.313,0.078,3.108,0.234,5.391h-1.411c-0.413-2.068-0.67-3.223-0.77-3.466c-0.143-0.342-0.392-0.588-0.748-0.737
                s-1.419-0.225-3.187-0.225h-5.858l0.107,12.576h8.959l0.791-0.021c1.226,0,1.995-0.05,2.31-0.15
                c0.541-0.185,1.019-0.495,1.432-0.93c0.414-0.435,1.177-1.664,2.288-3.689c0.114-0.199,0.285-0.492,0.514-0.877h1.476
                c-0.172,0.456-1.056,3.138-2.651,8.042c-5.189-0.157-9.929-0.235-14.22-0.235c-2.993,0-6.301,0.078-9.921,0.235v-1.262l3.464-0.748
                c0.556-0.157,0.897-0.314,1.026-0.472c0.214-0.271,0.32-0.834,0.32-1.689v-0.385l0.107-5.026c0.014-0.97,0.021-2.873,0.021-5.71
                c0-4.122-0.015-7.141-0.043-9.059s-0.1-3.005-0.214-3.262c-0.086-0.171-0.218-0.31-0.396-0.417
                c-0.179-0.106-0.838-0.288-1.978-0.545l-2.31-0.515v-1.261c2.68,0.156,5.88,0.234,9.601,0.234c2.038,0,6.885-0.078,14.54-0.234
                c0.028,2.467,0.199,4.904,0.513,7.314h-1.497c-0.57-1.938-1.033-3.229-1.39-3.871c-0.242-0.442-0.542-0.734-0.897-0.877
                c-0.471-0.186-1.512-0.278-3.122-0.278h-8.639l-0.149,6.888L242.349,127.15z"></path>
            </g>
            <g>
              <g>
                <path fill="#00365C" d="M5.609,159.035l0.218,0.411c-0.493,0.324-0.948,0.555-1.366,0.69s-0.854,0.203-1.311,0.203
                  c-0.964,0-1.721-0.274-2.273-0.823s-0.828-1.289-0.828-2.219c0-0.986,0.311-1.779,0.932-2.383
                  c0.622-0.602,1.481-0.901,2.579-0.901c0.351,0,0.689,0.027,1.016,0.082c0.327,0.057,0.731,0.16,1.213,0.311
                  c-0.018,0.186-0.026,0.378-0.026,0.578c0,0.345,0.021,0.768,0.064,1.266h-0.47c-0.143-0.547-0.237-0.867-0.282-0.962
                  c-0.105-0.214-0.279-0.396-0.52-0.544c-0.241-0.148-0.567-0.223-0.977-0.223c-0.625,0-1.106,0.191-1.445,0.574
                  c-0.411,0.459-0.616,1.093-0.616,1.903c0,0.838,0.205,1.477,0.614,1.914c0.409,0.438,0.96,0.657,1.653,0.657
                  C4.398,159.57,5.007,159.392,5.609,159.035z"></path>
                <path fill="#00365C" d="M6.627,158.089c0-0.632,0.196-1.144,0.588-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.644,0,1.148,0.18,1.512,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.171-0.188-1.529-0.565C6.805,159.373,6.627,158.82,6.627,158.089z
                  M7.905,157.865c0,0.801,0.112,1.378,0.337,1.73c0.127,0.202,0.311,0.303,0.55,0.303c0.219,0,0.388-0.086,0.507-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.107-1.332-0.32-1.658c-0.131-0.205-0.314-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.521,0.265C7.992,156.895,7.905,157.298,7.905,157.865z"></path>
                <path fill="#00365C" d="M11.232,154.821v-0.3c0.419-0.101,0.74-0.19,0.964-0.271c0.224-0.08,0.465-0.188,0.725-0.327l0.209,0.15
                  c-0.04,0.364-0.07,0.795-0.092,1.292c-0.021,0.496-0.032,1.059-0.032,1.688c0,1.359,0.003,2.126,0.01,2.305
                  c0.007,0.178,0.034,0.291,0.08,0.34c0.068,0.074,0.25,0.146,0.547,0.214v0.3c-0.447-0.031-0.852-0.048-1.214-0.048
                  c-0.371,0-0.779,0.017-1.227,0.048v-0.3c0.316-0.071,0.503-0.137,0.56-0.197c0.057-0.06,0.091-0.193,0.103-0.401
                  c0.017-0.302,0.025-1.104,0.025-2.406c0-1.199-0.008-1.84-0.025-1.923c-0.026-0.132-0.124-0.198-0.295-0.198
                  C11.494,154.786,11.381,154.798,11.232,154.821z"></path>
                <path fill="#00365C" d="M13.875,154.821v-0.3c0.419-0.101,0.74-0.19,0.964-0.271c0.224-0.08,0.465-0.188,0.725-0.327l0.209,0.15
                  c-0.04,0.364-0.07,0.795-0.092,1.292c-0.021,0.496-0.032,1.059-0.032,1.688c0,1.359,0.004,2.126,0.011,2.305
                  c0.007,0.178,0.034,0.291,0.08,0.34c0.068,0.074,0.25,0.146,0.547,0.214v0.3c-0.447-0.031-0.852-0.048-1.214-0.048
                  c-0.371,0-0.78,0.017-1.227,0.048v-0.3c0.316-0.071,0.503-0.137,0.56-0.197c0.057-0.06,0.091-0.193,0.103-0.401
                  c0.017-0.302,0.025-1.104,0.025-2.406c0-1.199-0.008-1.84-0.025-1.923c-0.026-0.132-0.124-0.198-0.295-0.198
                  C14.136,154.786,14.023,154.798,13.875,154.821z"></path>
                <path fill="#00365C" d="M20.102,159.053l0.222,0.325c-0.448,0.378-0.86,0.653-1.236,0.824c-0.163,0.074-0.371,0.112-0.625,0.112
                  c-0.53,0-0.935-0.157-1.214-0.471c-0.354-0.394-0.53-0.918-0.53-1.574c0-0.682,0.203-1.235,0.607-1.66
                  c0.405-0.425,0.907-0.637,1.506-0.637c0.547,0,0.948,0.165,1.201,0.496c0.168,0.217,0.265,0.554,0.291,1.013
                  c-0.143,0.095-0.275,0.201-0.398,0.321c-0.299-0.011-0.604-0.018-0.915-0.018c-0.405,0-0.782,0.007-1.129,0.018
                  c-0.003,0.063-0.004,0.115-0.004,0.158c0,0.402,0.092,0.757,0.275,1.062c0.184,0.306,0.46,0.458,0.828,0.458
                  c0.18,0,0.351-0.03,0.515-0.091C19.66,159.33,19.862,159.218,20.102,159.053z M19.067,155.498h-0.376
                  c-0.472-0.551-0.897-1.017-1.275-1.396L17.484,154c0.205,0.012,0.375,0.017,0.512,0.017c0.105,0,0.299-0.005,0.58-0.017
                  c0.074,0.254,0.134,0.448,0.18,0.582L19.067,155.498z M17.891,157.362c0.16,0.005,0.291,0.009,0.394,0.009
                  c0.245,0,0.544-0.012,0.898-0.034c-0.018-0.364-0.078-0.606-0.182-0.727c-0.104-0.12-0.248-0.18-0.43-0.18
                  c-0.177,0-0.328,0.071-0.453,0.215C17.992,156.788,17.916,157.027,17.891,157.362z"></path>
                <path fill="#00365C" d="M24.938,156.113l0.042,0.082c-0.094,0.153-0.177,0.316-0.248,0.487c-0.183-0.035-0.369-0.053-0.561-0.057
                  c0.146,0.258,0.218,0.509,0.218,0.755c0,0.271-0.067,0.52-0.204,0.746c-0.135,0.227-0.341,0.406-0.617,0.541
                  c-0.275,0.133-0.564,0.201-0.867,0.201c-0.065,0-0.145-0.005-0.236-0.014c-0.091-0.008-0.195-0.02-0.313-0.034
                  c-0.18,0.162-0.27,0.291-0.27,0.386c0,0.051,0.033,0.093,0.099,0.128c0.103,0.055,0.357,0.081,0.762,0.081l0.707-0.009
                  c0.577,0,0.958,0.075,1.144,0.227s0.278,0.381,0.278,0.689c0,0.305-0.091,0.584-0.274,0.837c-0.182,0.254-0.474,0.459-0.875,0.613
                  s-0.87,0.23-1.409,0.23c-0.59,0-1.035-0.104-1.334-0.316c-0.208-0.149-0.312-0.33-0.312-0.544c0-0.086,0.018-0.157,0.055-0.214
                  s0.112-0.121,0.226-0.192c0.238-0.148,0.446-0.292,0.623-0.433c-0.216-0.076-0.369-0.174-0.458-0.29
                  c-0.089-0.117-0.134-0.252-0.134-0.406c0-0.091,0.012-0.159,0.036-0.205c0.024-0.046,0.076-0.102,0.156-0.167
                  c0.292-0.231,0.504-0.406,0.635-0.526c-0.242-0.116-0.416-0.252-0.524-0.407c-0.153-0.216-0.23-0.479-0.23-0.786
                  c0-0.442,0.15-0.803,0.449-1.083c0.299-0.278,0.724-0.418,1.274-0.418c0.137,0,0.274,0.012,0.413,0.037
                  c0.138,0.024,0.277,0.063,0.417,0.112c0.32,0.005,0.486,0.009,0.5,0.009C24.391,156.174,24.667,156.153,24.938,156.113z
                  M22.013,160.374c-0.268,0.208-0.402,0.407-0.402,0.599c0,0.131,0.079,0.256,0.237,0.377c0.158,0.119,0.398,0.179,0.72,0.179
                  c0.431,0,0.768-0.076,1.012-0.23s0.366-0.33,0.366-0.526c0-0.12-0.043-0.208-0.128-0.265c-0.111-0.077-0.341-0.116-0.688-0.116
                  l-0.53,0.009C22.505,160.399,22.31,160.391,22.013,160.374z M22.184,157.324c0,0.433,0.076,0.761,0.227,0.984
                  c0.089,0.131,0.211,0.197,0.368,0.197c0.131,0,0.245-0.068,0.34-0.203c0.095-0.137,0.144-0.392,0.144-0.765
                  c0-0.439-0.069-0.76-0.206-0.963c-0.089-0.128-0.211-0.192-0.368-0.192c-0.139,0-0.259,0.068-0.356,0.203
                  C22.233,156.722,22.184,156.968,22.184,157.324z"></path>
                <path fill="#00365C" d="M28.552,159.053l0.222,0.325c-0.447,0.378-0.859,0.653-1.235,0.824c-0.163,0.074-0.371,0.112-0.625,0.112
                  c-0.53,0-0.935-0.157-1.214-0.471c-0.354-0.394-0.531-0.918-0.531-1.574c0-0.682,0.203-1.235,0.607-1.66s0.907-0.637,1.505-0.637
                  c0.547,0,0.948,0.165,1.202,0.496c0.168,0.217,0.265,0.554,0.291,1.013c-0.143,0.095-0.275,0.201-0.397,0.321
                  c-0.299-0.011-0.604-0.018-0.915-0.018c-0.405,0-0.781,0.007-1.129,0.018c-0.002,0.063-0.004,0.115-0.004,0.158
                  c0,0.402,0.092,0.757,0.276,1.062c0.184,0.306,0.459,0.458,0.828,0.458c0.179,0,0.351-0.03,0.515-0.091
                  C28.11,159.33,28.312,159.218,28.552,159.053z M26.341,157.362c0.16,0.005,0.291,0.009,0.394,0.009
                  c0.245,0,0.544-0.012,0.898-0.034c-0.017-0.364-0.078-0.606-0.182-0.727c-0.104-0.12-0.247-0.18-0.43-0.18
                  c-0.177,0-0.328,0.071-0.454,0.215C26.442,156.788,26.366,157.027,26.341,157.362z"></path>
                <path fill="#00365C" d="M31.293,154.458v-0.316c0.688,0.031,1.435,0.046,2.243,0.046c0.936,0,1.9-0.015,2.893-0.046
                  c0.009,0.597,0.044,1.116,0.107,1.558h-0.364c-0.034-0.091-0.059-0.161-0.073-0.209c-0.14-0.406-0.249-0.644-0.33-0.708
                  c-0.08-0.065-0.26-0.098-0.54-0.098h-1.636c-0.023,0.481-0.037,1.18-0.043,2.096l0.746,0.009c0.389,0,0.628-0.038,0.718-0.112
                  c0.09-0.076,0.185-0.331,0.285-0.764h0.355c-0.032,0.418-0.047,0.788-0.047,1.107c0,0.305,0.016,0.686,0.047,1.142H35.3
                  c-0.081-0.46-0.157-0.724-0.232-0.792c-0.074-0.067-0.298-0.103-0.673-0.103h-0.84v2.393h1.702c0.188,0,0.313-0.012,0.376-0.035
                  c0.1-0.037,0.19-0.104,0.273-0.198c0.174-0.192,0.378-0.496,0.612-0.913h0.376c-0.166,0.47-0.344,1.036-0.535,1.698
                  c-0.973-0.031-1.989-0.048-3.049-0.048c-0.482,0-1.155,0.017-2.019,0.048v-0.316c0.473-0.086,0.744-0.154,0.813-0.206
                  c0.094-0.065,0.141-0.23,0.141-0.496v-0.146l0.009-1.694v-1.368l-0.004-0.633c0-0.305-0.016-0.495-0.047-0.569
                  c-0.031-0.075-0.092-0.133-0.182-0.176C31.933,154.564,31.689,154.515,31.293,154.458z"></path>
                <path fill="#00365C" d="M37.211,156.417v-0.3c0.462,0.031,0.858,0.048,1.189,0.048c0.336,0,0.727-0.017,1.172-0.048v0.3
                  c-0.237,0.055-0.373,0.096-0.406,0.124c-0.046,0.04-0.069,0.089-0.069,0.146c0,0.049,0.012,0.108,0.034,0.18l0.633,1.763
                  l0.624-1.771c0.02-0.063,0.03-0.118,0.03-0.163c0-0.049-0.018-0.092-0.055-0.131c-0.038-0.038-0.137-0.088-0.299-0.147v-0.3
                  c0.328,0.031,0.588,0.048,0.782,0.048c0.205,0,0.448-0.017,0.727-0.048v0.3c-0.265,0.086-0.438,0.187-0.52,0.302
                  s-0.323,0.708-0.727,1.777c-0.22,0.579-0.462,1.186-0.725,1.818H39.25l-0.906-2.226l-0.529-1.266
                  c-0.055-0.094-0.108-0.161-0.161-0.203C37.602,156.579,37.454,156.512,37.211,156.417z"></path>
                <path fill="#00365C" d="M42.347,157.508l-0.38-0.043c0.083-0.403,0.135-0.762,0.158-1.074c0.52-0.251,1.063-0.375,1.628-0.375
                  c0.328,0,0.59,0.052,0.786,0.158c0.195,0.105,0.33,0.249,0.404,0.432c0.075,0.183,0.111,0.467,0.111,0.852l-0.021,1.455
                  c0,0.275,0.023,0.45,0.07,0.523c0.047,0.072,0.109,0.108,0.186,0.108c0.091,0,0.227-0.05,0.406-0.149l0.163,0.248
                  c-0.388,0.274-0.674,0.453-0.86,0.539c-0.134,0.06-0.262,0.09-0.385,0.09c-0.163,0-0.302-0.045-0.419-0.133
                  c-0.117-0.089-0.197-0.22-0.239-0.394c-0.342,0.236-0.582,0.385-0.719,0.442c-0.137,0.059-0.302,0.088-0.496,0.088
                  c-0.293,0-0.534-0.099-0.72-0.297c-0.187-0.198-0.28-0.463-0.28-0.793c0-0.234,0.063-0.451,0.188-0.65
                  c0.085-0.141,0.244-0.273,0.475-0.403l1.553-0.735c-0.006-0.339-0.06-0.567-0.161-0.685s-0.251-0.175-0.451-0.175
                  c-0.202,0-0.378,0.066-0.528,0.199C42.666,156.869,42.51,157.125,42.347,157.508z M43.956,157.858
                  c-0.471,0.22-0.767,0.399-0.89,0.54c-0.123,0.139-0.184,0.321-0.184,0.547c0,0.23,0.046,0.401,0.137,0.509
                  c0.091,0.108,0.21,0.163,0.355,0.163c0.111,0,0.219-0.037,0.323-0.111c0.104-0.074,0.173-0.179,0.209-0.314
                  s0.054-0.42,0.054-0.854C43.959,158.237,43.958,158.078,43.956,157.858z"></path>
                <path fill="#00365C" d="M45.862,154.821v-0.3c0.419-0.101,0.74-0.19,0.964-0.271c0.224-0.08,0.466-0.188,0.725-0.327l0.209,0.15
                  c-0.04,0.364-0.07,0.795-0.091,1.292c-0.021,0.496-0.032,1.059-0.032,1.688c0,1.359,0.003,2.126,0.011,2.305
                  c0.007,0.178,0.033,0.291,0.079,0.34c0.068,0.074,0.251,0.146,0.547,0.214v0.3c-0.447-0.031-0.852-0.048-1.214-0.048
                  c-0.371,0-0.779,0.017-1.227,0.048v-0.3c0.316-0.071,0.503-0.137,0.56-0.197c0.057-0.06,0.091-0.193,0.103-0.401
                  c0.017-0.302,0.025-1.104,0.025-2.406c0-1.199-0.009-1.84-0.025-1.923c-0.026-0.132-0.125-0.198-0.295-0.198
                  C46.124,154.786,46.011,154.798,45.862,154.821z"></path>
                <path fill="#00365C" d="M53.214,159.549v0.316c-0.411,0.054-0.738,0.113-0.982,0.177c-0.244,0.064-0.485,0.148-0.725,0.251
                  l-0.201-0.129c0.035-0.196,0.06-0.401,0.077-0.615c-0.362,0.333-0.614,0.537-0.757,0.611c-0.143,0.073-0.308,0.111-0.496,0.111
                  c-0.216,0-0.403-0.039-0.558-0.118c-0.156-0.078-0.271-0.178-0.349-0.299c-0.077-0.121-0.127-0.28-0.149-0.477
                  c-0.012-0.126-0.018-0.451-0.018-0.976l0.004-0.853c0-0.467-0.031-0.739-0.094-0.816s-0.26-0.129-0.59-0.158v-0.286
                  c0.65-0.049,1.21-0.143,1.681-0.283l0.197,0.125c-0.023,0.143-0.041,0.353-0.056,0.633c-0.023,0.504-0.034,0.948-0.034,1.33
                  c0,0.591,0.04,0.971,0.118,1.143c0.079,0.171,0.226,0.256,0.442,0.256c0.171,0,0.317-0.053,0.438-0.162
                  c0.121-0.108,0.2-0.273,0.237-0.496c0.026-0.14,0.039-0.59,0.039-1.348c0-0.442-0.026-0.695-0.079-0.759
                  c-0.053-0.064-0.252-0.115-0.597-0.152v-0.286c0.496-0.049,0.851-0.092,1.065-0.131c0.214-0.038,0.423-0.089,0.628-0.152
                  l0.201,0.121c-0.042,0.256-0.07,0.499-0.082,0.727c-0.022,0.379-0.034,0.95-0.034,1.716c0,0.502,0.014,0.785,0.043,0.851
                  c0.037,0.092,0.127,0.137,0.27,0.137h0.047C52.973,159.554,53.077,159.552,53.214,159.549z"></path>
                <path fill="#00365C" d="M54.201,157.508l-0.38-0.043c0.083-0.403,0.136-0.762,0.158-1.074c0.52-0.251,1.063-0.375,1.628-0.375
                  c0.328,0,0.59,0.052,0.786,0.158c0.195,0.105,0.33,0.249,0.404,0.432c0.075,0.183,0.112,0.467,0.112,0.852l-0.021,1.455
                  c0,0.275,0.023,0.45,0.07,0.523c0.047,0.072,0.109,0.108,0.186,0.108c0.091,0,0.227-0.05,0.406-0.149l0.163,0.248
                  c-0.388,0.274-0.674,0.453-0.859,0.539c-0.134,0.06-0.263,0.09-0.385,0.09c-0.163,0-0.302-0.045-0.419-0.133
                  c-0.117-0.089-0.197-0.22-0.239-0.394c-0.342,0.236-0.582,0.385-0.719,0.442c-0.137,0.059-0.302,0.088-0.496,0.088
                  c-0.293,0-0.534-0.099-0.72-0.297s-0.28-0.463-0.28-0.793c0-0.234,0.063-0.451,0.188-0.65c0.085-0.141,0.244-0.273,0.475-0.403
                  l1.552-0.735c-0.006-0.339-0.059-0.567-0.161-0.685c-0.101-0.117-0.251-0.175-0.451-0.175c-0.202,0-0.378,0.066-0.528,0.199
                  C54.52,156.869,54.364,157.125,54.201,157.508z M55.81,157.858c-0.47,0.22-0.767,0.399-0.89,0.54
                  c-0.123,0.139-0.184,0.321-0.184,0.547c0,0.23,0.046,0.401,0.137,0.509c0.091,0.108,0.21,0.163,0.355,0.163
                  c0.112,0,0.219-0.037,0.323-0.111c0.104-0.074,0.174-0.179,0.209-0.314s0.053-0.42,0.053-0.854
                  C55.813,158.237,55.812,158.078,55.81,157.858z"></path>
                <path fill="#00365C" d="M60.595,156.16l0.056,0.06c-0.091,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.988-0.069
                  l-0.013,0.51l-0.013,0.586l-0.013,0.582c0,0.545,0.045,0.886,0.135,1.024s0.208,0.207,0.353,0.207
                  c0.111,0,0.291-0.054,0.539-0.162l0.154,0.309c-0.431,0.259-0.717,0.414-0.86,0.466c-0.191,0.068-0.374,0.103-0.547,0.103
                  c-0.266,0-0.478-0.084-0.635-0.252c-0.158-0.169-0.238-0.416-0.238-0.744l0.018-0.988l0.034-1.64l-0.346-0.008
                  c-0.031,0-0.12,0.003-0.265,0.008v-0.316c0.273-0.097,0.525-0.254,0.754-0.475c0.229-0.219,0.461-0.544,0.695-0.976h0.376
                  c-0.075,0.475-0.119,0.901-0.13,1.279c0.249,0.007,0.4,0.009,0.454,0.009C59.977,156.169,60.217,156.167,60.595,156.16z"></path>
                <path fill="#00365C" d="M60.821,156.858v-0.309c0.647-0.114,1.206-0.312,1.676-0.595l0.218,0.115
                  c-0.04,0.346-0.065,0.653-0.077,0.924c-0.02,0.402-0.03,1.038-0.03,1.908c0,0.365,0.013,0.598,0.039,0.697
                  c0.017,0.065,0.05,0.115,0.1,0.147c0.05,0.033,0.221,0.088,0.511,0.165v0.3c-0.396-0.031-0.758-0.048-1.086-0.048
                  c-0.313,0-0.771,0.017-1.373,0.048v-0.3l0.111-0.025c0.25-0.058,0.399-0.106,0.445-0.148c0.045-0.041,0.074-0.114,0.085-0.22
                  c0.025-0.271,0.039-0.83,0.039-1.677c0-0.491-0.013-0.776-0.039-0.855c-0.034-0.106-0.116-0.158-0.244-0.158
                  C61.086,156.828,60.961,156.838,60.821,156.858z M62.065,153.983c0.191,0,0.354,0.067,0.49,0.203
                  c0.135,0.135,0.203,0.298,0.203,0.489s-0.068,0.354-0.203,0.49s-0.298,0.203-0.49,0.203c-0.191,0-0.354-0.067-0.489-0.203
                  c-0.136-0.136-0.203-0.299-0.203-0.49s0.066-0.354,0.201-0.489C61.708,154.051,61.872,153.983,62.065,153.983z"></path>
                <path fill="#00365C" d="M63.7,158.089c0-0.632,0.196-1.144,0.588-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.644,0,1.148,0.18,1.512,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.171-0.188-1.529-0.565C63.878,159.373,63.7,158.82,63.7,158.089z
                  M64.978,157.865c0,0.801,0.112,1.378,0.337,1.73c0.127,0.202,0.311,0.303,0.55,0.303c0.219,0,0.388-0.086,0.507-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.107-1.332-0.32-1.658c-0.131-0.205-0.314-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.521,0.265C65.065,156.895,64.978,157.298,64.978,157.865z"></path>
                <path fill="#00365C" d="M68.322,156.892v-0.308c0.376-0.08,0.689-0.164,0.939-0.253c0.25-0.088,0.497-0.2,0.742-0.337l0.222,0.128
                  c-0.04,0.217-0.067,0.453-0.081,0.71c0.316-0.358,0.555-0.585,0.714-0.678c0.16-0.093,0.348-0.139,0.564-0.139
                  c0.214,0,0.399,0.041,0.554,0.123c0.155,0.083,0.271,0.188,0.346,0.314c0.076,0.127,0.125,0.298,0.147,0.512
                  c0.014,0.134,0.021,0.456,0.021,0.966l-0.004,0.763c0,0.49,0.006,0.778,0.017,0.866c0.012,0.086,0.039,0.149,0.083,0.188
                  c0.044,0.039,0.17,0.08,0.378,0.127c0.034,0.008,0.088,0.021,0.163,0.038v0.3c-0.476-0.031-0.845-0.048-1.108-0.048
                  c-0.265,0-0.656,0.017-1.171,0.048v-0.3c0.22-0.055,0.354-0.104,0.402-0.147s0.078-0.118,0.09-0.221
                  c0.017-0.143,0.026-0.614,0.026-1.416c0-0.568-0.024-0.91-0.073-1.026c-0.08-0.186-0.229-0.278-0.449-0.278
                  c-0.16,0-0.306,0.057-0.438,0.171c-0.132,0.114-0.217,0.26-0.254,0.437s-0.056,0.566-0.056,1.168c0,0.539,0.012,0.874,0.034,1.005
                  c0.012,0.071,0.037,0.124,0.077,0.156c0.04,0.033,0.174,0.084,0.402,0.152v0.3c-0.433-0.031-0.795-0.048-1.086-0.048
                  c-0.271,0-0.671,0.017-1.202,0.048v-0.3c0.313-0.083,0.491-0.143,0.533-0.18c0.041-0.037,0.069-0.122,0.083-0.257
                  c0.025-0.308,0.039-0.706,0.039-1.198c0-0.749-0.009-1.162-0.025-1.235c-0.035-0.126-0.121-0.188-0.261-0.188
                  C68.602,156.854,68.479,156.866,68.322,156.892z"></path>
                <path fill="#00365C" d="M75.434,154.458v-0.316c0.947,0.031,1.644,0.046,2.092,0.046c1.18,0,2.21-0.015,3.092-0.046
                  c0.017,0.633,0.055,1.194,0.115,1.686h-0.351l-0.042-0.133c-0.12-0.388-0.23-0.655-0.33-0.805
                  c-0.043-0.061-0.104-0.104-0.184-0.131c-0.08-0.026-0.238-0.04-0.475-0.04h-1.638v2.066h0.744c0.288,0,0.468-0.015,0.541-0.043
                  c0.072-0.028,0.13-0.078,0.173-0.149c0.065-0.111,0.138-0.345,0.218-0.701h0.355c-0.034,0.433-0.051,0.846-0.051,1.235
                  c0,0.356,0.017,0.711,0.051,1.064H79.39c-0.094-0.418-0.16-0.67-0.197-0.753c-0.031-0.06-0.077-0.102-0.137-0.128
                  c-0.077-0.031-0.268-0.046-0.573-0.046h-0.77v1.916c0,0.23,0.014,0.375,0.041,0.433c0.027,0.057,0.073,0.098,0.139,0.123
                  c0.106,0.046,0.337,0.09,0.693,0.133c0.045,0.003,0.112,0.012,0.201,0.026v0.316c-0.704-0.031-1.296-0.048-1.774-0.048
                  c-0.479,0-1.005,0.017-1.579,0.048v-0.316c0.491-0.086,0.768-0.154,0.833-0.206c0.064-0.051,0.102-0.146,0.113-0.282
                  c0.007-0.103,0.013-0.882,0.017-2.34v-1.154v-0.352c0-0.425-0.011-0.676-0.032-0.754c-0.021-0.076-0.057-0.131-0.108-0.162
                  c-0.066-0.04-0.283-0.093-0.65-0.158C75.568,154.481,75.511,154.472,75.434,154.458z"></path>
                <path fill="#00365C" d="M80.843,158.089c0-0.632,0.196-1.144,0.588-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.644,0,1.148,0.18,1.511,0.541c0.364,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.171-0.188-1.529-0.565C81.022,159.373,80.843,158.82,80.843,158.089z
                  M82.122,157.865c0,0.801,0.112,1.378,0.337,1.73c0.128,0.202,0.311,0.303,0.55,0.303c0.219,0,0.388-0.086,0.507-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.107-1.332-0.32-1.658c-0.131-0.205-0.314-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.521,0.265C82.209,156.895,82.122,157.298,82.122,157.865z"></path>
                <path fill="#00365C" d="M85.458,156.888v-0.3c0.707-0.154,1.272-0.348,1.694-0.582l0.205,0.116
                  c-0.048,0.3-0.077,0.629-0.085,0.988c0.291-0.456,0.505-0.737,0.643-0.843c0.139-0.105,0.302-0.158,0.49-0.158
                  c0.128,0,0.269,0.034,0.423,0.103c-0.088,0.356-0.157,0.726-0.206,1.107h-0.291c-0.014-0.114-0.051-0.197-0.111-0.252
                  c-0.06-0.055-0.131-0.081-0.214-0.081c-0.125,0-0.244,0.058-0.355,0.175c-0.168,0.18-0.285,0.4-0.351,0.663
                  c-0.034,0.134-0.051,0.365-0.051,0.693c0,0.579,0.005,0.913,0.017,1.001s0.042,0.154,0.094,0.196
                  c0.051,0.043,0.21,0.095,0.475,0.154c0.037,0.008,0.093,0.022,0.167,0.043v0.3c-0.542-0.031-0.979-0.048-1.313-0.048
                  c-0.359,0-0.77,0.017-1.232,0.048v-0.3c0.342-0.071,0.536-0.151,0.582-0.239c0.066-0.123,0.099-0.709,0.099-1.759
                  c0-0.557-0.021-0.872-0.064-0.947c-0.043-0.076-0.12-0.113-0.231-0.113C85.757,156.854,85.628,156.865,85.458,156.888z"></path>
                <path fill="#00365C" d="M89.076,156.88v-0.292c0.644-0.131,1.21-0.328,1.698-0.59l0.201,0.107
                  c-0.037,0.213-0.064,0.459-0.081,0.739c0.331-0.372,0.574-0.604,0.729-0.694s0.341-0.135,0.558-0.135
                  c0.296,0,0.538,0.077,0.723,0.232c0.137,0.111,0.244,0.311,0.321,0.597c0.273-0.32,0.511-0.539,0.712-0.656
                  c0.201-0.115,0.416-0.173,0.644-0.173c0.251,0,0.464,0.06,0.639,0.18c0.176,0.119,0.288,0.277,0.336,0.472
                  c0.049,0.195,0.073,0.56,0.073,1.093l-0.004,0.677c0,0.647,0.013,1.036,0.039,1.167c0.011,0.061,0.042,0.108,0.09,0.144
                  c0.048,0.036,0.219,0.091,0.513,0.165v0.3c-0.465-0.031-0.843-0.048-1.133-0.048s-0.67,0.017-1.137,0.048v-0.3
                  c0.245-0.068,0.388-0.123,0.43-0.165c0.041-0.041,0.066-0.113,0.075-0.22c0.014-0.137,0.021-0.608,0.021-1.419
                  c0-0.421-0.01-0.69-0.03-0.807c-0.028-0.165-0.084-0.284-0.169-0.359c-0.084-0.073-0.191-0.11-0.323-0.11
                  c-0.174,0-0.329,0.06-0.464,0.18c-0.135,0.119-0.222,0.289-0.259,0.508c-0.02,0.117-0.03,0.525-0.03,1.227
                  c0,0.487,0.006,0.767,0.017,0.842c0.011,0.074,0.042,0.131,0.092,0.173c0.05,0.041,0.183,0.092,0.4,0.151v0.3
                  c-0.368-0.031-0.707-0.048-1.018-0.048c-0.293,0-0.668,0.017-1.125,0.048v-0.3c0.225-0.06,0.361-0.11,0.408-0.151
                  c0.047-0.042,0.077-0.103,0.088-0.182c0.017-0.108,0.026-0.519,0.026-1.23c0-0.553-0.006-0.875-0.018-0.966
                  c-0.022-0.193-0.075-0.333-0.158-0.421c-0.082-0.086-0.201-0.13-0.355-0.13c-0.176,0-0.328,0.058-0.455,0.173
                  s-0.208,0.257-0.242,0.425s-0.051,0.434-0.051,0.794c0,0.824,0.003,1.268,0.009,1.329c0.008,0.089,0.041,0.159,0.098,0.21
                  c0.057,0.052,0.191,0.101,0.402,0.149v0.3c-0.479-0.031-0.858-0.048-1.138-0.048s-0.663,0.017-1.15,0.048v-0.3
                  c0.348-0.08,0.542-0.156,0.581-0.231c0.057-0.103,0.085-0.661,0.085-1.675c0-0.595-0.011-0.923-0.034-0.985
                  c-0.042-0.12-0.143-0.181-0.299-0.181C89.315,156.84,89.204,156.854,89.076,156.88z"></path>
                <path fill="#00365C" d="M97.222,157.508l-0.38-0.043c0.083-0.403,0.135-0.762,0.158-1.074c0.52-0.251,1.063-0.375,1.627-0.375
                  c0.328,0,0.59,0.052,0.786,0.158c0.195,0.105,0.33,0.249,0.404,0.432s0.111,0.467,0.111,0.852l-0.021,1.455
                  c0,0.275,0.024,0.45,0.07,0.523c0.047,0.072,0.109,0.108,0.187,0.108c0.091,0,0.227-0.05,0.406-0.149l0.163,0.248
                  c-0.388,0.274-0.675,0.453-0.86,0.539c-0.134,0.06-0.262,0.09-0.385,0.09c-0.163,0-0.302-0.045-0.419-0.133
                  c-0.117-0.089-0.196-0.22-0.239-0.394c-0.342,0.236-0.582,0.385-0.718,0.442c-0.137,0.059-0.302,0.088-0.497,0.088
                  c-0.293,0-0.533-0.099-0.72-0.297c-0.187-0.198-0.28-0.463-0.28-0.793c0-0.234,0.063-0.451,0.188-0.65
                  c0.086-0.141,0.244-0.273,0.475-0.403l1.552-0.735c-0.006-0.339-0.059-0.567-0.16-0.685s-0.252-0.175-0.451-0.175
                  c-0.202,0-0.379,0.066-0.528,0.199C97.541,156.869,97.385,157.125,97.222,157.508z M98.83,157.858
                  c-0.47,0.22-0.767,0.399-0.89,0.54c-0.122,0.139-0.184,0.321-0.184,0.547c0,0.23,0.046,0.401,0.137,0.509
                  c0.091,0.108,0.209,0.163,0.355,0.163c0.111,0,0.219-0.037,0.323-0.111c0.104-0.074,0.174-0.179,0.209-0.314
                  s0.054-0.42,0.054-0.854C98.834,158.237,98.833,158.078,98.83,157.858z"></path>
                <path fill="#00365C" d="M103.615,156.16l0.056,0.06c-0.091,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.987-0.069
                  l-0.013,0.51l-0.013,0.586l-0.013,0.582c0,0.545,0.045,0.886,0.135,1.024c0.089,0.139,0.207,0.207,0.353,0.207
                  c0.111,0,0.291-0.054,0.539-0.162l0.154,0.309c-0.43,0.259-0.717,0.414-0.86,0.466c-0.19,0.068-0.373,0.103-0.547,0.103
                  c-0.266,0-0.477-0.084-0.635-0.252c-0.158-0.169-0.237-0.416-0.237-0.744l0.017-0.988l0.034-1.64l-0.346-0.008
                  c-0.032,0-0.12,0.003-0.265,0.008v-0.316c0.273-0.097,0.525-0.254,0.754-0.475c0.229-0.219,0.461-0.544,0.695-0.976h0.376
                  c-0.076,0.475-0.119,0.901-0.131,1.279c0.249,0.007,0.4,0.009,0.454,0.009C102.997,156.169,103.238,156.167,103.615,156.16z"></path>
                <path fill="#00365C" d="M103.842,156.858v-0.309c0.646-0.114,1.206-0.312,1.676-0.595l0.218,0.115
                  c-0.04,0.346-0.065,0.653-0.077,0.924c-0.02,0.402-0.03,1.038-0.03,1.908c0,0.365,0.013,0.598,0.039,0.697
                  c0.017,0.065,0.05,0.115,0.1,0.147c0.05,0.033,0.221,0.088,0.512,0.165v0.3c-0.396-0.031-0.759-0.048-1.086-0.048
                  c-0.314,0-0.771,0.017-1.373,0.048v-0.3l0.111-0.025c0.251-0.058,0.399-0.106,0.445-0.148c0.045-0.041,0.074-0.114,0.086-0.22
                  c0.025-0.271,0.038-0.83,0.038-1.677c0-0.491-0.013-0.776-0.038-0.855c-0.035-0.106-0.116-0.158-0.244-0.158
                  C104.107,156.828,103.981,156.838,103.842,156.858z M105.086,153.983c0.191,0,0.354,0.067,0.489,0.203
                  c0.136,0.135,0.203,0.298,0.203,0.489s-0.067,0.354-0.203,0.49c-0.135,0.136-0.298,0.203-0.489,0.203
                  c-0.191,0-0.354-0.067-0.49-0.203s-0.203-0.299-0.203-0.49s0.067-0.354,0.201-0.489
                  C104.729,154.051,104.893,153.983,105.086,153.983z"></path>
                <path fill="#00365C" d="M106.72,158.089c0-0.632,0.196-1.144,0.588-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.645,0,1.148,0.18,1.512,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.661,0-1.171-0.188-1.529-0.565C106.899,159.373,106.72,158.82,106.72,158.089z
                  M107.999,157.865c0,0.801,0.112,1.378,0.337,1.73c0.128,0.202,0.311,0.303,0.55,0.303c0.219,0,0.388-0.086,0.507-0.26
                  c0.17-0.248,0.256-0.676,0.256-1.285c0-0.779-0.107-1.332-0.32-1.658c-0.131-0.205-0.314-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.521,0.265C108.085,156.895,107.999,157.298,107.999,157.865z"></path>
                <path fill="#00365C" d="M111.343,156.892v-0.308c0.376-0.08,0.689-0.164,0.939-0.253c0.25-0.088,0.497-0.2,0.742-0.337
                  l0.222,0.128c-0.04,0.217-0.067,0.453-0.081,0.71c0.316-0.358,0.555-0.585,0.714-0.678s0.348-0.139,0.564-0.139
                  c0.213,0,0.398,0.041,0.554,0.123c0.155,0.083,0.271,0.188,0.346,0.314c0.076,0.127,0.125,0.298,0.147,0.512
                  c0.014,0.134,0.021,0.456,0.021,0.966l-0.004,0.763c0,0.49,0.006,0.778,0.017,0.866c0.011,0.086,0.039,0.149,0.083,0.188
                  c0.044,0.039,0.171,0.08,0.379,0.127c0.034,0.008,0.088,0.021,0.163,0.038v0.3c-0.477-0.031-0.846-0.048-1.108-0.048
                  c-0.265,0-0.656,0.017-1.172,0.048v-0.3c0.22-0.055,0.354-0.104,0.402-0.147c0.049-0.044,0.079-0.118,0.09-0.221
                  c0.017-0.143,0.025-0.614,0.025-1.416c0-0.568-0.024-0.91-0.073-1.026c-0.08-0.186-0.229-0.278-0.449-0.278
                  c-0.159,0-0.306,0.057-0.438,0.171s-0.218,0.26-0.254,0.437c-0.037,0.177-0.056,0.566-0.056,1.168
                  c0,0.539,0.011,0.874,0.034,1.005c0.012,0.071,0.037,0.124,0.077,0.156c0.04,0.033,0.173,0.084,0.402,0.152v0.3
                  c-0.433-0.031-0.795-0.048-1.086-0.048c-0.271,0-0.671,0.017-1.202,0.048v-0.3c0.313-0.083,0.491-0.143,0.533-0.18
                  c0.041-0.037,0.069-0.122,0.083-0.257c0.025-0.308,0.038-0.706,0.038-1.198c0-0.749-0.008-1.162-0.025-1.235
                  c-0.034-0.126-0.121-0.188-0.261-0.188C111.623,156.854,111.5,156.866,111.343,156.892z"></path>
                <path fill="#00365C" d="M122.209,160.212v-0.316c0.345-0.063,0.544-0.115,0.599-0.155c0.054-0.039,0.081-0.094,0.081-0.163
                  c0-0.039-0.009-0.086-0.025-0.137c-0.046-0.12-0.194-0.48-0.444-1.081c-0.401-0.008-0.735-0.013-1.002-0.013
                  c-0.219,0-0.585,0.005-1.097,0.013c-0.32,0.749-0.48,1.16-0.48,1.231c0,0.068,0.023,0.119,0.068,0.152
                  c0.046,0.032,0.248,0.083,0.607,0.152v0.316c-0.359-0.031-0.703-0.048-1.031-0.048c-0.354,0-0.713,0.017-1.078,0.048v-0.316
                  c0.257-0.055,0.434-0.116,0.53-0.185c0.065-0.047,0.125-0.115,0.179-0.206c0.111-0.18,0.444-0.876,0.999-2.087
                  c0.352-0.768,0.853-1.901,1.503-3.404h0.342c0.266,0.669,0.814,1.92,1.646,3.75c0.462,1.019,0.739,1.597,0.83,1.737
                  c0.063,0.097,0.125,0.165,0.188,0.205s0.261,0.103,0.595,0.189v0.316c-0.505-0.031-0.974-0.048-1.407-0.048
                  C123.385,160.164,122.851,160.181,122.209,160.212z M121.392,155.857l-0.874,2.014l0.847,0.004c0.202,0,0.493-0.001,0.873-0.004
                  L121.392,155.857z"></path>
                <path fill="#00365C" d="M128.795,157.524l-0.385-0.017c-0.029-0.405-0.112-0.686-0.25-0.842c-0.138-0.157-0.313-0.235-0.524-0.235
                  c-0.262,0-0.472,0.099-0.628,0.298c-0.208,0.269-0.312,0.653-0.312,1.157c0,0.517,0.114,0.908,0.342,1.176s0.5,0.401,0.817,0.401
                  c0.14,0,0.28-0.022,0.421-0.066c0.142-0.044,0.33-0.13,0.567-0.259l0.201,0.325c-0.476,0.371-0.825,0.605-1.046,0.703
                  c-0.221,0.099-0.457,0.148-0.708,0.148c-0.508,0-0.928-0.178-1.261-0.533c-0.334-0.354-0.501-0.836-0.501-1.443
                  c0-0.647,0.196-1.204,0.586-1.669c0.39-0.464,0.934-0.696,1.629-0.696c0.402,0,0.835,0.106,1.3,0.32
                  c-0.057,0.229-0.113,0.497-0.167,0.809C128.842,157.284,128.815,157.425,128.795,157.524z"></path>
                <path fill="#00365C" d="M132.721,157.524l-0.385-0.017c-0.028-0.405-0.112-0.686-0.25-0.842c-0.138-0.157-0.313-0.235-0.524-0.235
                  c-0.262,0-0.472,0.099-0.628,0.298c-0.208,0.269-0.312,0.653-0.312,1.157c0,0.517,0.114,0.908,0.342,1.176s0.5,0.401,0.817,0.401
                  c0.14,0,0.28-0.022,0.421-0.066s0.33-0.13,0.566-0.259l0.201,0.325c-0.476,0.371-0.825,0.605-1.046,0.703
                  c-0.221,0.099-0.457,0.148-0.707,0.148c-0.508,0-0.929-0.178-1.262-0.533c-0.333-0.354-0.501-0.836-0.501-1.443
                  c0-0.647,0.196-1.204,0.586-1.669c0.39-0.464,0.934-0.696,1.629-0.696c0.402,0,0.835,0.106,1.3,0.32
                  c-0.057,0.229-0.112,0.497-0.167,0.809C132.768,157.284,132.741,157.425,132.721,157.524z"></path>
                <path fill="#00365C" d="M133.178,156.888v-0.3c0.707-0.154,1.272-0.348,1.694-0.582l0.205,0.116
                  c-0.048,0.3-0.077,0.629-0.085,0.988c0.291-0.456,0.505-0.737,0.643-0.843c0.139-0.105,0.302-0.158,0.49-0.158
                  c0.128,0,0.269,0.034,0.423,0.103c-0.088,0.356-0.157,0.726-0.206,1.107h-0.291c-0.014-0.114-0.051-0.197-0.111-0.252
                  c-0.06-0.055-0.131-0.081-0.214-0.081c-0.125,0-0.244,0.058-0.355,0.175c-0.168,0.18-0.285,0.4-0.351,0.663
                  c-0.034,0.134-0.051,0.365-0.051,0.693c0,0.579,0.005,0.913,0.017,1.001s0.043,0.154,0.094,0.196
                  c0.051,0.043,0.21,0.095,0.475,0.154c0.037,0.008,0.093,0.022,0.167,0.043v0.3c-0.542-0.031-0.979-0.048-1.313-0.048
                  c-0.359,0-0.77,0.017-1.232,0.048v-0.3c0.342-0.071,0.536-0.151,0.582-0.239c0.065-0.123,0.098-0.709,0.098-1.759
                  c0-0.557-0.021-0.872-0.064-0.947c-0.043-0.076-0.12-0.113-0.231-0.113C133.478,156.854,133.349,156.865,133.178,156.888z"></path>
                <path fill="#00365C" d="M140.123,159.053l0.223,0.325c-0.447,0.378-0.86,0.653-1.236,0.824c-0.163,0.074-0.371,0.112-0.625,0.112
                  c-0.53,0-0.935-0.157-1.214-0.471c-0.354-0.394-0.53-0.918-0.53-1.574c0-0.682,0.203-1.235,0.607-1.66
                  c0.405-0.425,0.907-0.637,1.506-0.637c0.547,0,0.948,0.165,1.202,0.496c0.167,0.217,0.265,0.554,0.291,1.013
                  c-0.143,0.095-0.275,0.201-0.397,0.321c-0.299-0.011-0.605-0.018-0.916-0.018c-0.405,0-0.781,0.007-1.129,0.018
                  c-0.003,0.063-0.004,0.115-0.004,0.158c0,0.402,0.092,0.757,0.275,1.062c0.184,0.306,0.46,0.458,0.828,0.458
                  c0.179,0,0.351-0.03,0.515-0.091C139.682,159.33,139.884,159.218,140.123,159.053z M137.913,157.362
                  c0.16,0.005,0.291,0.009,0.393,0.009c0.246,0,0.545-0.012,0.898-0.034c-0.017-0.364-0.078-0.606-0.182-0.727
                  c-0.104-0.12-0.247-0.18-0.43-0.18c-0.176,0-0.328,0.071-0.454,0.215C138.013,156.788,137.938,157.027,137.913,157.362z
                  M139.906,154.014l0.055,0.11c-0.43,0.419-0.861,0.876-1.292,1.374h-0.376l0.509-1.484c0.285,0.01,0.475,0.017,0.569,0.017
                  C139.476,154.03,139.654,154.023,139.906,154.014z"></path>
                <path fill="#00365C" d="M142.848,154.838v-0.312c0.652-0.14,1.22-0.341,1.702-0.604l0.205,0.15
                  c-0.039,0.361-0.069,0.777-0.089,1.249c-0.02,0.47-0.03,0.995-0.03,1.574c0,1.034,0.003,1.767,0.009,2.194
                  c0.002,0.199,0.028,0.324,0.077,0.374c0.048,0.05,0.135,0.075,0.261,0.075c0.029,0,0.135-0.001,0.316-0.005v0.3
                  c-0.351,0.049-0.65,0.105-0.9,0.171c-0.25,0.064-0.519,0.153-0.806,0.266l-0.209-0.103c0.031-0.18,0.055-0.366,0.073-0.557
                  c-0.326,0.271-0.595,0.456-0.809,0.557c-0.151,0.071-0.313,0.106-0.487,0.106c-0.336,0-0.62-0.145-0.851-0.432
                  c-0.303-0.368-0.454-0.849-0.454-1.441c0-0.286,0.04-0.571,0.122-0.856c0.081-0.285,0.21-0.537,0.388-0.757
                  s0.416-0.402,0.711-0.55c0.294-0.146,0.596-0.22,0.904-0.22c0.069,0,0.146,0.007,0.231,0.021s0.18,0.036,0.282,0.064
                  c0.009-0.313,0.013-0.535,0.013-0.663c0-0.302-0.022-0.486-0.069-0.551c-0.045-0.064-0.126-0.096-0.244-0.096
                  C143.111,154.796,142.995,154.81,142.848,154.838z M143.501,157.97c0-0.582-0.041-0.957-0.124-1.125
                  c-0.108-0.217-0.282-0.325-0.521-0.325c-0.203,0-0.385,0.11-0.548,0.331c-0.163,0.222-0.243,0.6-0.243,1.137
                  c0,0.525,0.094,0.921,0.286,1.188c0.131,0.184,0.299,0.274,0.505,0.274c0.242,0,0.418-0.124,0.529-0.372
                  C143.463,158.898,143.501,158.529,143.501,157.97z"></path>
                <path fill="#00365C" d="M145.486,156.858v-0.309c0.646-0.114,1.205-0.312,1.676-0.595l0.219,0.115
                  c-0.041,0.346-0.066,0.653-0.077,0.924c-0.021,0.402-0.03,1.038-0.03,1.908c0,0.365,0.013,0.598,0.038,0.697
                  c0.018,0.065,0.051,0.115,0.101,0.147c0.05,0.033,0.221,0.088,0.512,0.165v0.3c-0.397-0.031-0.759-0.048-1.087-0.048
                  c-0.313,0-0.771,0.017-1.372,0.048v-0.3l0.11-0.025c0.251-0.058,0.399-0.106,0.445-0.148c0.045-0.041,0.073-0.114,0.086-0.22
                  c0.025-0.271,0.037-0.83,0.037-1.677c0-0.491-0.012-0.776-0.037-0.855c-0.035-0.106-0.116-0.158-0.244-0.158
                  C145.751,156.828,145.625,156.838,145.486,156.858z M146.73,153.983c0.19,0,0.354,0.067,0.489,0.203
                  c0.136,0.135,0.203,0.298,0.203,0.489s-0.067,0.354-0.203,0.49c-0.135,0.136-0.299,0.203-0.489,0.203
                  c-0.191,0-0.355-0.067-0.49-0.203c-0.136-0.136-0.203-0.299-0.203-0.49s0.067-0.354,0.201-0.489
                  C146.372,154.051,146.536,153.983,146.73,153.983z"></path>
                <path fill="#00365C" d="M151.016,156.16l0.055,0.06c-0.091,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.986-0.069
                  l-0.013,0.51l-0.014,0.586l-0.014,0.582c0,0.545,0.046,0.886,0.136,1.024c0.089,0.139,0.207,0.207,0.353,0.207
                  c0.11,0,0.291-0.054,0.538-0.162l0.155,0.309c-0.431,0.259-0.718,0.414-0.86,0.466c-0.19,0.068-0.373,0.103-0.547,0.103
                  c-0.266,0-0.477-0.084-0.635-0.252c-0.158-0.169-0.238-0.416-0.238-0.744l0.018-0.988l0.033-1.64l-0.345-0.008
                  c-0.032,0-0.12,0.003-0.266,0.008v-0.316c0.274-0.097,0.525-0.254,0.755-0.475c0.229-0.219,0.461-0.544,0.695-0.976h0.376
                  c-0.075,0.475-0.119,0.901-0.13,1.279c0.247,0.007,0.398,0.009,0.453,0.009C150.397,156.169,150.638,156.167,151.016,156.16z"></path>
                <path fill="#00365C" d="M152.016,157.508l-0.38-0.043c0.083-0.403,0.135-0.762,0.158-1.074c0.52-0.251,1.063-0.375,1.627-0.375
                  c0.328,0,0.591,0.052,0.787,0.158c0.193,0.105,0.329,0.249,0.404,0.432c0.073,0.183,0.11,0.467,0.11,0.852l-0.021,1.455
                  c0,0.275,0.023,0.45,0.071,0.523c0.046,0.072,0.108,0.108,0.186,0.108c0.091,0,0.226-0.05,0.406-0.149l0.162,0.248
                  c-0.387,0.274-0.674,0.453-0.859,0.539c-0.134,0.06-0.262,0.09-0.385,0.09c-0.162,0-0.302-0.045-0.42-0.133
                  c-0.115-0.089-0.195-0.22-0.238-0.394c-0.342,0.236-0.582,0.385-0.718,0.442c-0.138,0.059-0.304,0.088-0.497,0.088
                  c-0.294,0-0.533-0.099-0.721-0.297c-0.187-0.198-0.279-0.463-0.279-0.793c0-0.234,0.063-0.451,0.188-0.65
                  c0.085-0.141,0.244-0.273,0.476-0.403l1.552-0.735c-0.006-0.339-0.059-0.567-0.16-0.685s-0.252-0.175-0.451-0.175
                  c-0.203,0-0.38,0.066-0.529,0.199S152.179,157.125,152.016,157.508z M153.624,157.858c-0.471,0.22-0.768,0.399-0.89,0.54
                  c-0.122,0.139-0.184,0.321-0.184,0.547c0,0.23,0.045,0.401,0.136,0.509c0.092,0.108,0.211,0.163,0.355,0.163
                  c0.111,0,0.219-0.037,0.323-0.111c0.104-0.074,0.173-0.179,0.209-0.314c0.035-0.136,0.054-0.42,0.054-0.854
                  C153.628,158.237,153.627,158.078,153.624,157.858z"></path>
                <path fill="#00365C" d="M158.409,156.16l0.056,0.06c-0.092,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.987-0.069
                  l-0.013,0.51l-0.013,0.586l-0.014,0.582c0,0.545,0.046,0.886,0.135,1.024c0.09,0.139,0.208,0.207,0.353,0.207
                  c0.112,0,0.292-0.054,0.539-0.162l0.154,0.309c-0.43,0.259-0.717,0.414-0.859,0.466c-0.19,0.068-0.373,0.103-0.547,0.103
                  c-0.266,0-0.477-0.084-0.636-0.252c-0.158-0.169-0.236-0.416-0.236-0.744l0.017-0.988l0.034-1.64l-0.347-0.008
                  c-0.031,0-0.12,0.003-0.265,0.008v-0.316c0.273-0.097,0.524-0.254,0.754-0.475c0.23-0.219,0.462-0.544,0.695-0.976h0.376
                  c-0.075,0.475-0.118,0.901-0.13,1.279c0.248,0.007,0.399,0.009,0.454,0.009C157.791,156.169,158.032,156.167,158.409,156.16z"></path>
                <path fill="#00365C" d="M158.637,156.858v-0.309c0.646-0.114,1.205-0.312,1.676-0.595l0.218,0.115
                  c-0.04,0.346-0.064,0.653-0.076,0.924c-0.021,0.402-0.03,1.038-0.03,1.908c0,0.365,0.013,0.598,0.038,0.697
                  c0.018,0.065,0.051,0.115,0.101,0.147c0.05,0.033,0.22,0.088,0.512,0.165v0.3c-0.396-0.031-0.759-0.048-1.086-0.048
                  c-0.314,0-0.772,0.017-1.373,0.048v-0.3l0.111-0.025c0.25-0.058,0.398-0.106,0.444-0.148c0.045-0.041,0.074-0.114,0.086-0.22
                  c0.025-0.271,0.038-0.83,0.038-1.677c0-0.491-0.013-0.776-0.038-0.855c-0.035-0.106-0.116-0.158-0.244-0.158
                  C158.901,156.828,158.776,156.838,158.637,156.858z M159.881,153.983c0.19,0,0.354,0.067,0.489,0.203
                  c0.136,0.135,0.203,0.298,0.203,0.489s-0.067,0.354-0.203,0.49s-0.299,0.203-0.489,0.203c-0.191,0-0.354-0.067-0.49-0.203
                  c-0.135-0.136-0.203-0.299-0.203-0.49s0.067-0.354,0.201-0.489C159.522,154.051,159.687,153.983,159.881,153.983z"></path>
                <path fill="#00365C" d="M161.513,158.089c0-0.632,0.196-1.144,0.589-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.644,0,1.148,0.18,1.512,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.172-0.188-1.528-0.565C161.692,159.373,161.513,158.82,161.513,158.089z
                  M162.792,157.865c0,0.801,0.112,1.378,0.337,1.73c0.128,0.202,0.312,0.303,0.55,0.303c0.22,0,0.389-0.086,0.508-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.106-1.332-0.319-1.658c-0.132-0.205-0.314-0.308-0.55-0.308
                  c-0.223,0-0.396,0.088-0.521,0.265C162.879,156.895,162.792,157.298,162.792,157.865z"></path>
                <path fill="#00365C" d="M166.136,156.892v-0.308c0.377-0.08,0.689-0.164,0.939-0.253c0.249-0.088,0.496-0.2,0.742-0.337
                  l0.222,0.128c-0.04,0.217-0.066,0.453-0.081,0.71c0.316-0.358,0.554-0.585,0.715-0.678c0.159-0.093,0.347-0.139,0.563-0.139
                  c0.214,0,0.398,0.041,0.555,0.123c0.154,0.083,0.271,0.188,0.346,0.314c0.075,0.127,0.125,0.298,0.147,0.512
                  c0.015,0.134,0.021,0.456,0.021,0.966l-0.005,0.763c0,0.49,0.007,0.778,0.019,0.866c0.011,0.086,0.039,0.149,0.082,0.188
                  c0.045,0.039,0.171,0.08,0.379,0.127c0.034,0.008,0.088,0.021,0.162,0.038v0.3c-0.476-0.031-0.845-0.048-1.107-0.048
                  c-0.265,0-0.655,0.017-1.171,0.048v-0.3c0.219-0.055,0.354-0.104,0.401-0.147c0.049-0.044,0.078-0.118,0.09-0.221
                  c0.017-0.143,0.026-0.614,0.026-1.416c0-0.568-0.024-0.91-0.073-1.026c-0.079-0.186-0.229-0.278-0.449-0.278
                  c-0.159,0-0.306,0.057-0.438,0.171s-0.217,0.26-0.254,0.437s-0.056,0.566-0.056,1.168c0,0.539,0.011,0.874,0.034,1.005
                  c0.011,0.071,0.037,0.124,0.077,0.156c0.04,0.033,0.174,0.084,0.401,0.152v0.3c-0.433-0.031-0.796-0.048-1.086-0.048
                  c-0.271,0-0.672,0.017-1.202,0.048v-0.3c0.313-0.083,0.491-0.143,0.533-0.18c0.041-0.037,0.068-0.122,0.083-0.257
                  c0.025-0.308,0.039-0.706,0.039-1.198c0-0.749-0.009-1.162-0.026-1.235c-0.034-0.126-0.121-0.188-0.261-0.188
                  C166.416,156.854,166.293,156.866,166.136,156.892z"></path>
                <path fill="#00365C" d="M176.857,159.053l0.223,0.325c-0.447,0.378-0.859,0.653-1.235,0.824c-0.163,0.074-0.371,0.112-0.625,0.112
                  c-0.53,0-0.936-0.157-1.214-0.471c-0.354-0.394-0.53-0.918-0.53-1.574c0-0.682,0.202-1.235,0.606-1.66
                  c0.405-0.425,0.907-0.637,1.506-0.637c0.547,0,0.948,0.165,1.202,0.496c0.167,0.217,0.265,0.554,0.29,1.013
                  c-0.143,0.095-0.275,0.201-0.397,0.321c-0.299-0.011-0.604-0.018-0.916-0.018c-0.404,0-0.78,0.007-1.129,0.018
                  c-0.002,0.063-0.004,0.115-0.004,0.158c0,0.402,0.093,0.757,0.276,1.062c0.184,0.306,0.459,0.458,0.827,0.458
                  c0.18,0,0.352-0.03,0.515-0.091C176.416,159.33,176.618,159.218,176.857,159.053z M174.647,157.362
                  c0.159,0.005,0.29,0.009,0.393,0.009c0.246,0,0.545-0.012,0.897-0.034c-0.017-0.364-0.077-0.606-0.182-0.727
                  c-0.103-0.12-0.246-0.18-0.429-0.18c-0.177,0-0.328,0.071-0.454,0.215C174.748,156.788,174.673,157.027,174.647,157.362z"></path>
                <path fill="#00365C" d="M177.4,156.892v-0.308c0.377-0.08,0.69-0.164,0.939-0.253c0.249-0.088,0.496-0.2,0.742-0.337l0.222,0.128
                  c-0.04,0.217-0.066,0.453-0.081,0.71c0.316-0.358,0.555-0.585,0.715-0.678c0.159-0.093,0.348-0.139,0.563-0.139
                  c0.214,0,0.398,0.041,0.555,0.123c0.154,0.083,0.271,0.188,0.347,0.314c0.074,0.127,0.124,0.298,0.146,0.512
                  c0.015,0.134,0.021,0.456,0.021,0.966l-0.005,0.763c0,0.49,0.007,0.778,0.019,0.866c0.011,0.086,0.039,0.149,0.083,0.188
                  c0.044,0.039,0.17,0.08,0.378,0.127c0.034,0.008,0.089,0.021,0.162,0.038v0.3c-0.476-0.031-0.845-0.048-1.107-0.048
                  c-0.265,0-0.655,0.017-1.171,0.048v-0.3c0.219-0.055,0.354-0.104,0.401-0.147c0.049-0.044,0.078-0.118,0.09-0.221
                  c0.018-0.143,0.026-0.614,0.026-1.416c0-0.568-0.024-0.91-0.073-1.026c-0.079-0.186-0.229-0.278-0.449-0.278
                  c-0.159,0-0.306,0.057-0.438,0.171s-0.217,0.26-0.254,0.437s-0.056,0.566-0.056,1.168c0,0.539,0.012,0.874,0.034,1.005
                  c0.011,0.071,0.037,0.124,0.077,0.156c0.04,0.033,0.174,0.084,0.401,0.152v0.3c-0.433-0.031-0.795-0.048-1.086-0.048
                  c-0.271,0-0.672,0.017-1.202,0.048v-0.3c0.314-0.083,0.491-0.143,0.533-0.18c0.041-0.037,0.068-0.122,0.083-0.257
                  c0.025-0.308,0.039-0.706,0.039-1.198c0-0.749-0.009-1.162-0.025-1.235c-0.035-0.126-0.122-0.188-0.262-0.188
                  C177.681,156.854,177.558,156.866,177.4,156.892z"></path>
                <path fill="#00365C" d="M184.521,154.458v-0.316c0.516,0.031,1.04,0.046,1.573,0.046c0.556,0,1.106-0.015,1.65-0.046v0.316
                  c-0.433,0.073-0.688,0.127-0.764,0.16c-0.075,0.033-0.128,0.083-0.157,0.151c-0.03,0.068-0.046,0.278-0.046,0.626l0.005,0.295
                  l-0.005,1.099c0.405,0.015,0.839,0.021,1.3,0.021c0.417,0,0.781-0.007,1.091-0.021l-0.008-1.518c0-0.271-0.017-0.442-0.048-0.51
                  c-0.03-0.068-0.091-0.12-0.18-0.154c-0.067-0.028-0.312-0.078-0.73-0.149v-0.316c0.551,0.031,1.058,0.046,1.52,0.046
                  c0.435,0,1.004-0.015,1.709-0.046v0.316l-0.686,0.145c-0.075,0.021-0.128,0.045-0.158,0.073s-0.052,0.064-0.066,0.107
                  c-0.029,0.094-0.047,0.39-0.052,0.886c-0.006,0.786-0.009,1.3-0.009,1.539c0,0.976,0.009,1.694,0.025,2.156
                  c0.006,0.154,0.041,0.262,0.107,0.323c0.065,0.062,0.289,0.122,0.671,0.182l0.167,0.026v0.316c-0.603-0.031-1.14-0.048-1.61-0.048
                  c-0.465,0-1.005,0.017-1.618,0.048v-0.316l0.718-0.146c0.086-0.028,0.146-0.071,0.183-0.13c0.035-0.059,0.053-0.19,0.053-0.396
                  l0.013-1.887l-1.176-0.018c-0.054,0-0.459,0.006-1.215,0.018c0.003,1.167,0.012,1.863,0.026,2.092
                  c0.009,0.123,0.045,0.213,0.109,0.27c0.064,0.058,0.281,0.112,0.65,0.167c0.04,0.005,0.103,0.016,0.186,0.03v0.316
                  c-0.635-0.031-1.202-0.048-1.704-0.048c-0.518,0-1.025,0.017-1.524,0.048v-0.316c0.393-0.078,0.631-0.13,0.713-0.159
                  c0.083-0.028,0.143-0.072,0.179-0.134c0.035-0.062,0.054-0.196,0.054-0.405l0.017-1.668c0-0.87-0.005-1.612-0.014-2.229
                  c-0.003-0.303-0.026-0.493-0.072-0.569c-0.025-0.048-0.07-0.088-0.134-0.116C185.199,154.587,184.951,154.534,184.521,154.458z"></path>
                <path fill="#00365C" d="M195.144,159.053l0.223,0.325c-0.447,0.378-0.859,0.653-1.235,0.824c-0.163,0.074-0.371,0.112-0.625,0.112
                  c-0.53,0-0.936-0.157-1.214-0.471c-0.354-0.394-0.53-0.918-0.53-1.574c0-0.682,0.202-1.235,0.606-1.66
                  c0.405-0.425,0.907-0.637,1.506-0.637c0.547,0,0.948,0.165,1.202,0.496c0.167,0.217,0.265,0.554,0.29,1.013
                  c-0.143,0.095-0.275,0.201-0.397,0.321c-0.299-0.011-0.604-0.018-0.916-0.018c-0.404,0-0.78,0.007-1.129,0.018
                  c-0.002,0.063-0.004,0.115-0.004,0.158c0,0.402,0.093,0.757,0.276,1.062c0.184,0.306,0.459,0.458,0.827,0.458
                  c0.18,0,0.352-0.03,0.515-0.091C194.702,159.33,194.904,159.218,195.144,159.053z M192.934,157.362
                  c0.159,0.005,0.29,0.009,0.393,0.009c0.246,0,0.545-0.012,0.897-0.034c-0.017-0.364-0.077-0.606-0.182-0.727
                  c-0.103-0.12-0.246-0.18-0.429-0.18c-0.177,0-0.328,0.071-0.454,0.215C193.034,156.788,192.959,157.027,192.934,157.362z
                  M194.926,154.014l0.056,0.11c-0.431,0.419-0.86,0.876-1.292,1.374h-0.376l0.509-1.484c0.285,0.01,0.475,0.017,0.569,0.017
                  C194.497,154.03,194.675,154.023,194.926,154.014z"></path>
                <path fill="#00365C" d="M195.617,156.904v-0.295c0.466-0.116,0.795-0.211,0.991-0.282c0.195-0.071,0.428-0.18,0.698-0.324
                  l0.21,0.106c-0.034,0.217-0.057,0.443-0.068,0.68c0.345-0.358,0.568-0.567,0.671-0.624c0.183-0.1,0.381-0.149,0.595-0.149
                  c0.367,0,0.682,0.157,0.943,0.472c0.261,0.315,0.391,0.768,0.391,1.357c0,0.709-0.207,1.29-0.624,1.744
                  c-0.416,0.455-0.887,0.683-1.411,0.683c-0.108,0-0.214-0.013-0.316-0.039c-0.103-0.025-0.201-0.063-0.295-0.111
                  c-0.003,0.141-0.005,0.247-0.005,0.321c0,0.627,0.021,0.974,0.063,1.039c0.042,0.065,0.229,0.143,0.562,0.228
                  c0.031,0.008,0.08,0.021,0.146,0.038v0.3c-0.568-0.031-1.007-0.047-1.319-0.047c-0.319,0-0.744,0.016-1.272,0.047v-0.3
                  c0.317-0.08,0.509-0.149,0.574-0.21c0.065-0.06,0.103-0.142,0.11-0.244c0.021-0.23,0.03-0.948,0.03-2.15
                  c0-1.272-0.01-1.958-0.03-2.059c-0.031-0.145-0.125-0.218-0.281-0.218C195.895,156.866,195.774,156.88,195.617,156.904z
                  M197.401,159.553c0.113,0.089,0.225,0.155,0.331,0.201c0.107,0.045,0.209,0.068,0.306,0.068c0.237,0,0.415-0.084,0.534-0.252
                  c0.192-0.271,0.287-0.685,0.287-1.24c0-0.54-0.093-0.941-0.277-1.203c-0.132-0.186-0.297-0.278-0.497-0.278
                  c-0.165,0-0.312,0.066-0.439,0.199c-0.129,0.133-0.206,0.349-0.232,0.647C197.405,157.81,197.401,158.43,197.401,159.553z"></path>
                <path fill="#00365C" d="M201.28,157.508l-0.382-0.043c0.083-0.403,0.137-0.762,0.159-1.074c0.52-0.251,1.062-0.375,1.628-0.375
                  c0.327,0,0.59,0.052,0.785,0.158c0.195,0.105,0.331,0.249,0.404,0.432c0.074,0.183,0.111,0.467,0.111,0.852l-0.021,1.455
                  c0,0.275,0.022,0.45,0.07,0.523c0.047,0.072,0.109,0.108,0.186,0.108c0.091,0,0.227-0.05,0.406-0.149l0.162,0.248
                  c-0.388,0.274-0.673,0.453-0.858,0.539c-0.135,0.06-0.263,0.09-0.385,0.09c-0.163,0-0.303-0.045-0.42-0.133
                  c-0.116-0.089-0.197-0.22-0.239-0.394c-0.343,0.236-0.581,0.385-0.719,0.442c-0.137,0.059-0.302,0.088-0.496,0.088
                  c-0.293,0-0.533-0.099-0.721-0.297c-0.187-0.198-0.279-0.463-0.279-0.793c0-0.234,0.063-0.451,0.188-0.65
                  c0.086-0.141,0.244-0.273,0.475-0.403l1.553-0.735c-0.006-0.339-0.06-0.567-0.161-0.685c-0.101-0.117-0.251-0.175-0.45-0.175
                  c-0.202,0-0.379,0.066-0.528,0.199S201.442,157.125,201.28,157.508z M202.888,157.858c-0.471,0.22-0.767,0.399-0.89,0.54
                  c-0.123,0.139-0.184,0.321-0.184,0.547c0,0.23,0.046,0.401,0.137,0.509c0.092,0.108,0.209,0.163,0.355,0.163
                  c0.11,0,0.218-0.037,0.322-0.111s0.174-0.179,0.209-0.314c0.036-0.136,0.054-0.42,0.054-0.854
                  C202.892,158.237,202.891,158.078,202.888,157.858z"></path>
                <path fill="#00365C" d="M207.674,156.16l0.056,0.06c-0.092,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.988-0.069
                  l-0.012,0.51l-0.013,0.586l-0.014,0.582c0,0.545,0.046,0.886,0.135,1.024c0.09,0.139,0.207,0.207,0.353,0.207
                  c0.111,0,0.291-0.054,0.539-0.162l0.154,0.309c-0.431,0.259-0.718,0.414-0.859,0.466c-0.191,0.068-0.374,0.103-0.548,0.103
                  c-0.266,0-0.477-0.084-0.635-0.252c-0.158-0.169-0.237-0.416-0.237-0.744l0.018-0.988l0.033-1.64l-0.347-0.008
                  c-0.031,0-0.119,0.003-0.265,0.008v-0.316c0.273-0.097,0.525-0.254,0.755-0.475c0.229-0.219,0.462-0.544,0.695-0.976h0.376
                  c-0.075,0.475-0.119,0.901-0.131,1.279c0.249,0.007,0.4,0.009,0.455,0.009C207.055,156.169,207.297,156.167,207.674,156.16z"></path>
                <path fill="#00365C" d="M208.037,158.089c0-0.632,0.195-1.144,0.588-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.645,0,1.148,0.18,1.512,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.192,1.298-0.579,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.661,0-1.171-0.188-1.528-0.565C208.216,159.373,208.037,158.82,208.037,158.089z
                  M209.316,157.865c0,0.801,0.112,1.378,0.337,1.73c0.127,0.202,0.311,0.303,0.55,0.303c0.219,0,0.388-0.086,0.508-0.26
                  c0.171-0.248,0.255-0.676,0.255-1.285c0-0.779-0.105-1.332-0.319-1.658c-0.131-0.205-0.313-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.521,0.265C209.402,156.895,209.316,157.298,209.316,157.865z"></path>
                <path fill="#00365C" d="M212.896,158.663l-0.107-0.119c0.114-0.303,0.202-0.599,0.265-0.887c0.282,0.017,0.593,0.025,0.931,0.025
                  c0.527,0,0.965-0.009,1.315-0.025l0.098,0.128c-0.11,0.283-0.203,0.576-0.276,0.878c-0.438-0.017-0.91-0.025-1.414-0.025
                  C213.388,158.638,213.117,158.646,212.896,158.663z"></path>
                <path fill="#00365C" d="M222.996,157.059v0.316c-0.391,0.071-0.639,0.133-0.744,0.184c-0.051,0.025-0.09,0.074-0.115,0.145
                  c-0.026,0.071-0.038,0.291-0.038,0.66c0,0.586,0.008,1.104,0.025,1.554c-0.194,0.034-0.406,0.075-0.638,0.123
                  c-0.604,0.132-1.04,0.214-1.307,0.248c-0.266,0.035-0.552,0.052-0.856,0.052c-0.71,0-1.272-0.088-1.686-0.266
                  c-0.517-0.226-0.911-0.572-1.185-1.043s-0.41-1.032-0.41-1.682c0-1.154,0.426-2.045,1.278-2.67
                  c0.61-0.444,1.415-0.666,2.416-0.666c0.67,0,1.426,0.096,2.267,0.29c-0.006,0.168-0.008,0.304-0.008,0.406
                  c0,0.334,0.014,0.809,0.043,1.421h-0.438c-0.119-0.474-0.24-0.802-0.361-0.986s-0.309-0.331-0.563-0.44
                  c-0.256-0.11-0.567-0.165-0.935-0.165c-0.482,0-0.885,0.098-1.209,0.295c-0.323,0.197-0.574,0.502-0.752,0.914
                  c-0.179,0.411-0.268,0.886-0.268,1.422c0,0.855,0.202,1.503,0.604,1.942c0.404,0.439,0.935,0.658,1.594,0.658
                  c0.331,0,0.688-0.074,1.073-0.222v-1.316c0-0.286-0.015-0.472-0.043-0.554c-0.02-0.052-0.059-0.093-0.117-0.124
                  s-0.242-0.078-0.55-0.141c-0.034-0.009-0.087-0.021-0.158-0.039v-0.316c0.656,0.031,1.148,0.047,1.48,0.047
                  C221.64,157.105,222.172,157.09,222.996,157.059z"></path>
                <path fill="#00365C" d="M224.087,157.508l-0.382-0.043c0.083-0.403,0.137-0.762,0.159-1.074c0.52-0.251,1.062-0.375,1.628-0.375
                  c0.327,0,0.59,0.052,0.785,0.158c0.195,0.105,0.331,0.249,0.404,0.432c0.074,0.183,0.111,0.467,0.111,0.852l-0.021,1.455
                  c0,0.275,0.022,0.45,0.07,0.523c0.047,0.072,0.109,0.108,0.186,0.108c0.091,0,0.227-0.05,0.406-0.149l0.162,0.248
                  c-0.388,0.274-0.673,0.453-0.858,0.539c-0.135,0.06-0.263,0.09-0.385,0.09c-0.163,0-0.303-0.045-0.42-0.133
                  c-0.116-0.089-0.197-0.22-0.239-0.394c-0.343,0.236-0.581,0.385-0.719,0.442c-0.137,0.059-0.302,0.088-0.496,0.088
                  c-0.293,0-0.533-0.099-0.721-0.297c-0.187-0.198-0.279-0.463-0.279-0.793c0-0.234,0.063-0.451,0.188-0.65
                  c0.086-0.141,0.244-0.273,0.475-0.403l1.553-0.735c-0.006-0.339-0.06-0.567-0.161-0.685c-0.101-0.117-0.251-0.175-0.45-0.175
                  c-0.202,0-0.379,0.066-0.528,0.199S224.249,157.125,224.087,157.508z M225.694,157.858c-0.471,0.22-0.767,0.399-0.89,0.54
                  c-0.123,0.139-0.184,0.321-0.184,0.547c0,0.23,0.046,0.401,0.137,0.509c0.092,0.108,0.209,0.163,0.355,0.163
                  c0.11,0,0.218-0.037,0.322-0.111s0.174-0.179,0.209-0.314c0.036-0.136,0.054-0.42,0.054-0.854
                  C225.698,158.237,225.697,158.078,225.694,157.858z"></path>
                <path fill="#00365C" d="M227.889,158.796h0.385c0.074,0.394,0.196,0.674,0.369,0.84c0.172,0.167,0.373,0.251,0.601,0.251
                  c0.174,0,0.313-0.047,0.413-0.14c0.102-0.093,0.152-0.205,0.152-0.34c0-0.122-0.05-0.241-0.15-0.354
                  c-0.1-0.114-0.354-0.29-0.763-0.526c-0.409-0.237-0.683-0.453-0.819-0.646c-0.14-0.194-0.209-0.411-0.209-0.65
                  c0-0.35,0.141-0.647,0.423-0.892c0.282-0.243,0.687-0.365,1.215-0.365c0.379,0,0.768,0.085,1.167,0.257
                  c-0.037,0.157-0.079,0.518-0.124,1.081l-0.372-0.017c-0.061-0.341-0.162-0.581-0.306-0.722c-0.144-0.14-0.312-0.211-0.503-0.211
                  c-0.156,0-0.278,0.041-0.365,0.122s-0.13,0.186-0.13,0.314c0,0.111,0.038,0.213,0.115,0.304c0.105,0.125,0.341,0.291,0.705,0.496
                  c0.459,0.259,0.758,0.482,0.898,0.672c0.14,0.188,0.209,0.409,0.209,0.663c0,0.346-0.131,0.661-0.393,0.949
                  c-0.263,0.288-0.715,0.433-1.356,0.433c-0.37,0-0.777-0.066-1.223-0.197C227.865,159.615,227.885,159.176,227.889,158.796z"></path>
                <path fill="#00365C" d="M234.033,156.16l0.056,0.06c-0.092,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.987-0.069
                  l-0.013,0.51l-0.013,0.586l-0.014,0.582c0,0.545,0.046,0.886,0.135,1.024c0.09,0.139,0.208,0.207,0.353,0.207
                  c0.111,0,0.292-0.054,0.539-0.162l0.154,0.309c-0.431,0.259-0.717,0.414-0.859,0.466c-0.19,0.068-0.373,0.103-0.547,0.103
                  c-0.266,0-0.477-0.084-0.636-0.252c-0.158-0.169-0.237-0.416-0.237-0.744l0.018-0.988l0.033-1.64l-0.346-0.008
                  c-0.031,0-0.12,0.003-0.265,0.008v-0.316c0.273-0.097,0.524-0.254,0.754-0.475c0.23-0.219,0.462-0.544,0.695-0.976h0.376
                  c-0.075,0.475-0.118,0.901-0.13,1.279c0.248,0.007,0.399,0.009,0.454,0.009C233.415,156.169,233.656,156.167,234.033,156.16z"></path>
                <path fill="#00365C" d="M234.179,156.888v-0.3c0.707-0.154,1.271-0.348,1.693-0.582l0.206,0.116
                  c-0.05,0.3-0.078,0.629-0.086,0.988c0.29-0.456,0.505-0.737,0.644-0.843c0.138-0.105,0.302-0.158,0.489-0.158
                  c0.129,0,0.27,0.034,0.424,0.103c-0.089,0.356-0.157,0.726-0.205,1.107h-0.291c-0.015-0.114-0.051-0.197-0.111-0.252
                  s-0.132-0.081-0.214-0.081c-0.125,0-0.243,0.058-0.354,0.175c-0.169,0.18-0.285,0.4-0.352,0.663
                  c-0.034,0.134-0.051,0.365-0.051,0.693c0,0.579,0.006,0.913,0.017,1.001c0.012,0.088,0.043,0.154,0.095,0.196
                  c0.051,0.043,0.209,0.095,0.474,0.154c0.038,0.008,0.093,0.022,0.168,0.043v0.3c-0.542-0.031-0.98-0.048-1.313-0.048
                  c-0.358,0-0.77,0.017-1.231,0.048v-0.3c0.343-0.071,0.536-0.151,0.581-0.239c0.066-0.123,0.1-0.709,0.1-1.759
                  c0-0.557-0.021-0.872-0.064-0.947c-0.043-0.076-0.12-0.113-0.23-0.113C234.479,156.854,234.351,156.865,234.179,156.888z"></path>
                <path fill="#00365C" d="M237.766,158.089c0-0.632,0.196-1.144,0.588-1.533c0.393-0.389,0.914-0.583,1.564-0.583
                  c0.644,0,1.148,0.18,1.512,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.172-0.188-1.529-0.565C237.945,159.373,237.766,158.82,237.766,158.089z
                  M239.045,157.865c0,0.801,0.112,1.378,0.337,1.73c0.128,0.202,0.312,0.303,0.55,0.303c0.22,0,0.389-0.086,0.508-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.106-1.332-0.32-1.658c-0.131-0.205-0.313-0.308-0.549-0.308
                  c-0.223,0-0.396,0.088-0.521,0.265C239.132,156.895,239.045,157.298,239.045,157.865z"></path>
                <path fill="#00365C" d="M242.624,158.663l-0.106-0.119c0.113-0.303,0.202-0.599,0.265-0.887c0.282,0.017,0.592,0.025,0.931,0.025
                  c0.526,0,0.965-0.009,1.315-0.025l0.098,0.128c-0.11,0.283-0.203,0.576-0.277,0.878c-0.438-0.017-0.909-0.025-1.414-0.025
                  C243.117,158.638,242.846,158.646,242.624,158.663z"></path>
                <path fill="#00365C" d="M245.455,154.458v-0.316c0.688,0.031,1.436,0.046,2.243,0.046c0.937,0,1.9-0.015,2.894-0.046
                  c0.008,0.597,0.044,1.116,0.106,1.558h-0.364c-0.033-0.091-0.059-0.161-0.073-0.209c-0.14-0.406-0.249-0.644-0.329-0.708
                  c-0.08-0.065-0.26-0.098-0.539-0.098h-1.637c-0.022,0.481-0.036,1.18-0.043,2.096l0.746,0.009c0.389,0,0.628-0.038,0.718-0.112
                  c0.09-0.076,0.186-0.331,0.285-0.764h0.355c-0.031,0.418-0.047,0.788-0.047,1.107c0,0.305,0.016,0.686,0.047,1.142h-0.355
                  c-0.079-0.46-0.157-0.724-0.23-0.792c-0.075-0.067-0.3-0.103-0.674-0.103h-0.84v2.393h1.701c0.189,0,0.313-0.012,0.377-0.035
                  c0.1-0.037,0.19-0.104,0.273-0.198c0.174-0.192,0.378-0.496,0.611-0.913h0.377c-0.166,0.47-0.344,1.036-0.534,1.698
                  c-0.973-0.031-1.989-0.048-3.05-0.048c-0.481,0-1.155,0.017-2.019,0.048v-0.316c0.474-0.086,0.744-0.154,0.813-0.206
                  c0.094-0.065,0.142-0.23,0.142-0.496v-0.146l0.009-1.694v-1.368l-0.005-0.633c0-0.305-0.016-0.495-0.047-0.569
                  c-0.031-0.075-0.092-0.133-0.182-0.176S245.852,154.515,245.455,154.458z"></path>
                <path fill="#00365C" d="M251.331,156.892v-0.308c0.377-0.08,0.689-0.164,0.939-0.253c0.249-0.088,0.496-0.2,0.742-0.337
                  l0.222,0.128c-0.04,0.217-0.067,0.453-0.081,0.71c0.316-0.358,0.554-0.585,0.714-0.678s0.348-0.139,0.564-0.139
                  c0.214,0,0.398,0.041,0.554,0.123c0.155,0.083,0.271,0.188,0.347,0.314c0.075,0.127,0.125,0.298,0.147,0.512
                  c0.014,0.134,0.021,0.456,0.021,0.966l-0.005,0.763c0,0.49,0.006,0.778,0.018,0.866c0.011,0.086,0.039,0.149,0.083,0.188
                  c0.045,0.039,0.17,0.08,0.379,0.127c0.034,0.008,0.088,0.021,0.162,0.038v0.3c-0.476-0.031-0.845-0.048-1.107-0.048
                  c-0.265,0-0.656,0.017-1.172,0.048v-0.3c0.22-0.055,0.354-0.104,0.402-0.147s0.078-0.118,0.09-0.221
                  c0.017-0.143,0.025-0.614,0.025-1.416c0-0.568-0.023-0.91-0.073-1.026c-0.079-0.186-0.229-0.278-0.448-0.278
                  c-0.16,0-0.306,0.057-0.438,0.171s-0.218,0.26-0.254,0.437c-0.037,0.177-0.057,0.566-0.057,1.168c0,0.539,0.012,0.874,0.035,1.005
                  c0.011,0.071,0.036,0.124,0.076,0.156c0.04,0.033,0.174,0.084,0.402,0.152v0.3c-0.434-0.031-0.796-0.048-1.086-0.048
                  c-0.271,0-0.672,0.017-1.202,0.048v-0.3c0.313-0.083,0.491-0.143,0.533-0.18c0.041-0.037,0.068-0.122,0.082-0.257
                  c0.026-0.308,0.04-0.706,0.04-1.198c0-0.749-0.01-1.162-0.026-1.235c-0.034-0.126-0.121-0.188-0.261-0.188
                  C251.611,156.854,251.488,156.866,251.331,156.892z"></path>
                <path fill="#00365C" d="M259.114,156.16l0.056,0.06c-0.092,0.161-0.173,0.325-0.244,0.497c-0.305-0.031-0.634-0.055-0.987-0.069
                  l-0.013,0.51l-0.013,0.586l-0.014,0.582c0,0.545,0.046,0.886,0.135,1.024c0.09,0.139,0.208,0.207,0.353,0.207
                  c0.111,0,0.292-0.054,0.539-0.162l0.154,0.309c-0.43,0.259-0.717,0.414-0.859,0.466c-0.19,0.068-0.373,0.103-0.547,0.103
                  c-0.266,0-0.477-0.084-0.636-0.252c-0.158-0.169-0.236-0.416-0.236-0.744l0.017-0.988l0.034-1.64l-0.347-0.008
                  c-0.031,0-0.12,0.003-0.265,0.008v-0.316c0.273-0.097,0.524-0.254,0.754-0.475c0.23-0.219,0.462-0.544,0.695-0.976h0.376
                  c-0.075,0.475-0.118,0.901-0.13,1.279c0.248,0.007,0.399,0.009,0.454,0.009C258.496,156.169,258.737,156.167,259.114,156.16z"></path>
                <path fill="#00365C" d="M262.835,159.053l0.223,0.325c-0.447,0.378-0.86,0.653-1.236,0.824c-0.163,0.074-0.37,0.112-0.624,0.112
                  c-0.53,0-0.936-0.157-1.215-0.471c-0.354-0.394-0.529-0.918-0.529-1.574c0-0.682,0.202-1.235,0.606-1.66
                  c0.405-0.425,0.907-0.637,1.506-0.637c0.547,0,0.947,0.165,1.201,0.496c0.168,0.217,0.265,0.554,0.291,1.013
                  c-0.143,0.095-0.275,0.201-0.397,0.321c-0.3-0.011-0.605-0.018-0.916-0.018c-0.404,0-0.78,0.007-1.129,0.018
                  c-0.003,0.063-0.004,0.115-0.004,0.158c0,0.402,0.092,0.757,0.275,1.062c0.184,0.306,0.46,0.458,0.828,0.458
                  c0.179,0,0.351-0.03,0.515-0.091C262.394,159.33,262.596,159.218,262.835,159.053z M260.624,157.362
                  c0.16,0.005,0.291,0.009,0.394,0.009c0.245,0,0.545-0.012,0.897-0.034c-0.017-0.364-0.077-0.606-0.182-0.727
                  c-0.104-0.12-0.247-0.18-0.43-0.18c-0.176,0-0.327,0.071-0.453,0.215C260.725,156.788,260.65,157.027,260.624,157.362z
                  M262.617,154.014l0.056,0.11c-0.431,0.419-0.861,0.876-1.292,1.374h-0.376l0.509-1.484c0.285,0.01,0.475,0.017,0.569,0.017
                  C262.188,154.03,262.366,154.023,262.617,154.014z"></path>
                <path fill="#00365C" d="M263.37,156.888v-0.3c0.706-0.154,1.271-0.348,1.692-0.582l0.206,0.116
                  c-0.049,0.3-0.077,0.629-0.085,0.988c0.29-0.456,0.505-0.737,0.643-0.843c0.139-0.105,0.302-0.158,0.49-0.158
                  c0.128,0,0.269,0.034,0.423,0.103c-0.088,0.356-0.156,0.726-0.205,1.107h-0.291c-0.014-0.114-0.051-0.197-0.111-0.252
                  c-0.06-0.055-0.131-0.081-0.214-0.081c-0.125,0-0.243,0.058-0.354,0.175c-0.168,0.18-0.285,0.4-0.351,0.663
                  c-0.034,0.134-0.051,0.365-0.051,0.693c0,0.579,0.005,0.913,0.017,1.001s0.043,0.154,0.095,0.196
                  c0.051,0.043,0.209,0.095,0.474,0.154c0.037,0.008,0.093,0.022,0.167,0.043v0.3c-0.541-0.031-0.979-0.048-1.313-0.048
                  c-0.359,0-0.77,0.017-1.231,0.048v-0.3c0.342-0.071,0.535-0.151,0.581-0.239c0.065-0.123,0.099-0.709,0.099-1.759
                  c0-0.557-0.021-0.872-0.064-0.947c-0.042-0.076-0.12-0.113-0.23-0.113C263.67,156.854,263.541,156.865,263.37,156.888z"></path>
                <path fill="#00365C" d="M266.957,158.089c0-0.632,0.196-1.144,0.588-1.533c0.392-0.389,0.913-0.583,1.563-0.583
                  c0.645,0,1.148,0.18,1.512,0.541c0.364,0.36,0.546,0.857,0.546,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.172-0.188-1.529-0.565C267.136,159.373,266.957,158.82,266.957,158.089z
                  M268.236,157.865c0,0.801,0.112,1.378,0.337,1.73c0.127,0.202,0.311,0.303,0.55,0.303c0.219,0,0.389-0.086,0.508-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.106-1.332-0.32-1.658c-0.131-0.205-0.313-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.521,0.265C268.322,156.895,268.236,157.298,268.236,157.865z"></path>
                <path fill="#00365C" d="M271.563,154.821v-0.3c0.419-0.101,0.741-0.19,0.965-0.271c0.225-0.08,0.466-0.188,0.725-0.327l0.21,0.15
                  c-0.04,0.364-0.07,0.795-0.092,1.292c-0.021,0.496-0.032,1.059-0.032,1.688c0,1.359,0.003,2.126,0.011,2.305
                  c0.007,0.178,0.033,0.291,0.079,0.34c0.068,0.074,0.25,0.146,0.547,0.214v0.3c-0.447-0.031-0.853-0.048-1.214-0.048
                  c-0.371,0-0.78,0.017-1.228,0.048v-0.3c0.316-0.071,0.503-0.137,0.56-0.197c0.058-0.06,0.092-0.193,0.103-0.401
                  c0.018-0.302,0.026-1.104,0.026-2.406c0-1.199-0.009-1.84-0.026-1.923c-0.024-0.132-0.124-0.198-0.295-0.198
                  C271.824,154.786,271.711,154.798,271.563,154.821z"></path>
                <path fill="#00365C" d="M274.432,158.089c0-0.632,0.196-1.144,0.588-1.533c0.393-0.389,0.913-0.583,1.563-0.583
                  c0.645,0,1.148,0.18,1.513,0.541c0.363,0.36,0.545,0.857,0.545,1.49c0,0.73-0.193,1.298-0.58,1.702
                  c-0.386,0.405-0.907,0.608-1.563,0.608c-0.662,0-1.172-0.188-1.529-0.565C274.611,159.373,274.432,158.82,274.432,158.089z
                  M275.711,157.865c0,0.801,0.112,1.378,0.337,1.73c0.128,0.202,0.311,0.303,0.55,0.303c0.219,0,0.389-0.086,0.508-0.26
                  c0.171-0.248,0.256-0.676,0.256-1.285c0-0.779-0.106-1.332-0.32-1.658c-0.131-0.205-0.313-0.308-0.55-0.308
                  c-0.222,0-0.396,0.088-0.52,0.265C275.798,156.895,275.711,157.298,275.711,157.865z"></path>
                <path fill="#00365C" d="M283.348,156.113l0.043,0.082c-0.094,0.153-0.176,0.316-0.247,0.487c-0.183-0.035-0.369-0.053-0.561-0.057
                  c0.145,0.258,0.218,0.509,0.218,0.755c0,0.271-0.067,0.52-0.203,0.746s-0.341,0.406-0.617,0.541
                  c-0.275,0.133-0.564,0.201-0.867,0.201c-0.065,0-0.144-0.005-0.235-0.014c-0.092-0.008-0.195-0.02-0.313-0.034
                  c-0.18,0.162-0.27,0.291-0.27,0.386c0,0.051,0.033,0.093,0.099,0.128c0.103,0.055,0.356,0.081,0.762,0.081l0.708-0.009
                  c0.576,0,0.957,0.075,1.143,0.227s0.278,0.381,0.278,0.689c0,0.305-0.091,0.584-0.273,0.837c-0.183,0.254-0.475,0.459-0.874,0.613
                  c-0.401,0.154-0.871,0.23-1.41,0.23c-0.59,0-1.035-0.104-1.334-0.316c-0.208-0.149-0.313-0.33-0.313-0.544
                  c0-0.086,0.02-0.157,0.056-0.214c0.037-0.057,0.112-0.121,0.227-0.192c0.238-0.148,0.446-0.292,0.622-0.433
                  c-0.216-0.076-0.368-0.174-0.457-0.29c-0.091-0.117-0.135-0.252-0.135-0.406c0-0.091,0.013-0.159,0.036-0.205
                  c0.024-0.046,0.076-0.102,0.156-0.167c0.292-0.231,0.503-0.406,0.634-0.526c-0.241-0.116-0.416-0.252-0.523-0.407
                  c-0.153-0.216-0.229-0.479-0.229-0.786c0-0.442,0.149-0.803,0.448-1.083c0.3-0.278,0.725-0.418,1.274-0.418
                  c0.138,0,0.274,0.012,0.413,0.037c0.139,0.024,0.277,0.063,0.417,0.112c0.319,0.005,0.485,0.009,0.5,0.009
                  C282.801,156.174,283.077,156.153,283.348,156.113z M280.423,160.374c-0.268,0.208-0.401,0.407-0.401,0.599
                  c0,0.131,0.079,0.256,0.237,0.377c0.158,0.119,0.398,0.179,0.721,0.179c0.43,0,0.768-0.076,1.011-0.23
                  c0.244-0.154,0.366-0.33,0.366-0.526c0-0.12-0.043-0.208-0.128-0.265c-0.111-0.077-0.342-0.116-0.689-0.116l-0.529,0.009
                  C280.915,160.399,280.72,160.391,280.423,160.374z M280.594,157.324c0,0.433,0.076,0.761,0.228,0.984
                  c0.088,0.131,0.21,0.197,0.367,0.197c0.132,0,0.245-0.068,0.341-0.203c0.095-0.137,0.143-0.392,0.143-0.765
                  c0-0.439-0.068-0.76-0.205-0.963c-0.089-0.128-0.211-0.192-0.367-0.192c-0.14,0-0.26,0.068-0.357,0.203
                  C280.644,156.722,280.594,156.968,280.594,157.324z"></path>
                <path fill="#00365C" d="M283.593,156.858v-0.309c0.647-0.114,1.206-0.312,1.676-0.595l0.219,0.115
                  c-0.04,0.346-0.065,0.653-0.077,0.924c-0.021,0.402-0.03,1.038-0.03,1.908c0,0.365,0.014,0.598,0.039,0.697
                  c0.018,0.065,0.051,0.115,0.101,0.147c0.05,0.033,0.22,0.088,0.511,0.165v0.3c-0.396-0.031-0.758-0.048-1.086-0.048
                  c-0.313,0-0.771,0.017-1.373,0.048v-0.3l0.111-0.025c0.251-0.058,0.399-0.106,0.445-0.148c0.045-0.041,0.073-0.114,0.085-0.22
                  c0.026-0.271,0.039-0.83,0.039-1.677c0-0.491-0.013-0.776-0.039-0.855c-0.034-0.106-0.115-0.158-0.244-0.158
                  C283.857,156.828,283.733,156.838,283.593,156.858z M284.838,153.983c0.19,0,0.354,0.067,0.489,0.203
                  c0.135,0.135,0.203,0.298,0.203,0.489s-0.068,0.354-0.203,0.49c-0.136,0.136-0.299,0.203-0.489,0.203
                  c-0.191,0-0.354-0.067-0.49-0.203s-0.203-0.299-0.203-0.49s0.066-0.354,0.201-0.489
                  C284.479,154.051,284.644,153.983,284.838,153.983z"></path>
                <path fill="#00365C" d="M289.827,159.053l0.223,0.325c-0.447,0.378-0.86,0.653-1.236,0.824c-0.163,0.074-0.37,0.112-0.624,0.112
                  c-0.53,0-0.936-0.157-1.215-0.471c-0.354-0.394-0.529-0.918-0.529-1.574c0-0.682,0.202-1.235,0.606-1.66
                  c0.405-0.425,0.907-0.637,1.506-0.637c0.547,0,0.947,0.165,1.201,0.496c0.168,0.217,0.265,0.554,0.291,1.013
                  c-0.143,0.095-0.275,0.201-0.397,0.321c-0.3-0.011-0.605-0.018-0.916-0.018c-0.404,0-0.78,0.007-1.129,0.018
                  c-0.003,0.063-0.004,0.115-0.004,0.158c0,0.402,0.092,0.757,0.275,1.062c0.184,0.306,0.46,0.458,0.828,0.458
                  c0.179,0,0.351-0.03,0.515-0.091C289.386,159.33,289.588,159.218,289.827,159.053z M287.616,157.362
                  c0.16,0.005,0.291,0.009,0.394,0.009c0.245,0,0.545-0.012,0.897-0.034c-0.017-0.364-0.077-0.606-0.182-0.727
                  c-0.104-0.12-0.247-0.18-0.43-0.18c-0.176,0-0.327,0.071-0.453,0.215C287.717,156.788,287.643,157.027,287.616,157.362z"></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="card">
          <div class="card-body">
            <header>
              <h2 class="text-center">Connexion</h2>
            </header>
            <form method="post" @submit.prevent="loginUser">
              <div class="form-group">
                <label for="username">Adresse e-mail</label>
                <input
                  type="email"
                  name="username"
                  id="username"
                  v-model="loginData.username"
                  class="form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label for="password">Mot de passe</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  v-model="loginData.password"
                  class="form-control"
                  required
                />
              </div>

              <base-button v-bind:loading="loading" type="primary" nativeType="submit" size="block">Connexion</base-button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../http-common.js";

export default {
  name: "loginForm",

  data: function() {
    return {
      loginData: {
        username: "",
        password: ""
      },
      loading: false
    };
  },

  methods: {
    // #1 - Authentification de l'utilisateur (demande de token)
    loginUser: function() {
      this.loading = true;
      this.$store
        .dispatch("AUTH_REQUEST", this.loginData)
        .then(() => {
          // Authorization Header (JWT)
          // (Si un token est déjà enregistré en localStorage, il est passé à chaque requête.)
          // -> L'authentification (AUTH_REQUEST) s'est déroulée avec succès : on ajoute le token à toutes les requêtes :
          // HTTP.defaults.headers.common.Authorization = "Bearer " + this.$store.state.userToken;
          this.$notify({message: 'Connexion réussie !', type: 'success', verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000});
          this.loading = false;
        })
        .catch((error) => {
          this.$notify({message: error, type: 'danger', verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000});
          this.loading = false;
        });
    },
  },
};
</script>
