/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";

Vue.config.productionTip = false;

// Router (VueRouter)
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import router from "./router/index";

// Store (vuex)
import store from "./store.js";

// Axios
import { appConfig } from "./config.js";
import { HTTP } from "./http-common.js";

// Main Vue

import App from "./App";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    isDev: process.env.NODE_ENV !== "production",
  },
  created(){
    this.$store.commit('INIT_YEARS');

    // Use token from localStorage if exists
    this.$store.dispatch('PERSISTENT_USER');

    // Synchronisation du localStorage
    // L'application root surveille les mutations du Store (`subscribe`) et execute l'action STORAGE_SYNC pour mettre à jour le localStorage.
    this.$store.subscribe((mutation, state) => {
      this.$store
        .dispatch("STORAGE_SYNC", mutation)
        .then(
          (response) =>
            this.isDev && console.info("STORAGE_SYNC done", mutation)
        )
        .catch(
          (error) => this.isDev && console.warn("STORAGE_SYNC error", error)
        );
    });
  }
}).$mount("#app");
