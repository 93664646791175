/*
## Authentification

- La plupart des requêtes à l'API doivent être authentifiées (token dans authorization-header)
- Si un userToken est en localStorage : requête de connexion de l'utilisateur (LOGIN : vérif. token et récupération des infos)
- Sinon : Requête avec login/password (AUTH: vérif. identifiants, retourne un token) pour la connexion de l'utilisateur.

## Notes

	- DISPATCH : actions (invoquées hors ou depuis le store)
	- COMMIT : mutations (uniquement invoquées par le store)

	Les mutations mettent à jour l'état de l'application (`state`).

## LocalStorage

	Certaines données sont enregistrées localement : App fait un `subscribe` sur les mutations du Store et lance l'action (`dispatch`) `STORAGE_SYNC`
	Lorsque les mutations observées sont appelées (`commit`) les valeurs du `state` sont enregistrées (userToken, etc.)
	De cette manière le localStorage est uniquement modifié par l'action STORAGE_SYNC, lui même invoqué par des mutations.

	Certaines données du state (ex. utilisateur) sont chargées automatiquement depuis le locatStorage.

*/

import Vue from "vue";

// Store
import Vuex from "vuex";
Vue.use(Vuex);

// Axios
import { HTTP } from "./http-common.js";

// LocalStorage
// This will register the instance `Vue.$localStorage`
import Storage from "vue-web-storage";
Vue.use(Storage);

// Helpers
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";

import { get } from "./libs/helpers.js";

// Store
export default new Vuex.Store({
  state: {
    // Authentication
    userData: Vue.$localStorage.get("userData", null),

    // Context
    selectedYear: Vue.$localStorage.get("selectedYear", null),
    years: [],
    selectedCourseTag: Vue.$localStorage.get("selectedCourseTag", null),
    selectedCourse: Vue.$localStorage.get("selectedCourse", 0),

    // Stats annuelles

    updateTime: Vue.$localStorage.get("updateTime", null),
    globalStats: Vue.$localStorage.get("globalStats", {
      id: "",
      month: "",
      year: "",
      stats_courses: {
        countCourseTags: 0,
        countCourses: 0,
        minRegistered: 0,
        maxRegistered: 0,
        averageRegistered: 0,
        averageProgression: 0,
        endedCourses: 0,
        totalRegistration: 0
      },
      stats_members: {
        total: 0,
        monthly: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        liberals: 0,
        employees: 0,
        intervalsAge: [0, 0, 0, 0, 0],
        averageAge: 0
      },
      stats_qi: {},
      updated_on: "",
      updateTime: ""
    }),
    topFlopCourses: Vue.$localStorage.get("topFlopCourses", null),

    // Action DPC

    coursesTags: Vue.$localStorage.get("coursesTags", []),
    courses: Vue.$localStorage.get("courses", []),
    users: Vue.$localStorage.get("users", []),
    qis: Vue.$localStorage.get("qis", []),
    timelogs: [] //Vue.$localStorage.get("timelogs", [])
  },
  mutations: {
    // Year and courses contexts
    INIT_YEARS: state => {
      var currentYear = new Date().getFullYear();
      if (null == state.selectedYear) state.selectedYear = currentYear;
      state.years = [];
      const endYear = 2021;
      while (endYear <= currentYear) {
        state.years.push(currentYear--);
      }
    },
    CHANGE_YEAR: (state, selectedYear) => {
      state.selectedYear = parseInt(selectedYear, 10);
    },
    CHANGE_COURSE_TAG: (state, selectedCourseTag) => {
      state.selectedCourseTag = parseInt(selectedCourseTag, 10);
    },
    CHANGE_COURSE: (state, selectedCourse) => {
      state.selectedCourse = parseInt(selectedCourse, 10);
    },

    // Retrieve authentified user data
    USER_LOGIN: (state, userData) => {
      state.userData = userData;
      HTTP.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${state.userData.token}`;
    },
    USER_LOGOUT: state => {
      state.userData = null;
      HTTP.defaults.headers.common["Authorization"] = "";
      clearInterval(window.heartbeatClock);
    },

    // Stats
    GET_GLOBAL_STATS: (state, globalStats) => {
      state.globalStats = globalStats;
      state.updateTime = globalStats.updateTime;
    },
    GET_COURSES: (state, courses) => {
      state.courses = courses;
    },
    GET_COURSES_TOPFLOP: (state, courses) => {
      state.topFlopCourses = courses;
    },
    GET_COURSES_TAGS: (state, coursesTags) => {
      state.coursesTags = coursesTags;
    },
    GET_USERS: (state, users) => {
      state.users = users;
    },
    GET_QIS: (state, qis) => {
      state.qis = qis;
    },
    GET_TIMELOGS: (state, timelogs) => {
      state.timelogs = timelogs;
    }
  },

  actions: {
    // localStorage Sync
    // L'App root subscribe aux mutations du Store pour mettre en cache les données de certaines mutations.
    STORAGE_SYNC: ({ commit, state }, mutation) => {
      return new Promise((resolve, reject) => {
        console.log("STORAGE_SYNC", mutation, state);

        // Connexion de l'utilisateur : on enregistre ses données
        if (mutation.type === "USER_LOGIN") {
          Vue.$localStorage.set("userData", state.userData);
          resolve(mutation);
        }

        // Erreur de login ou Logout : on supprime les données de l'utilisateur
        if (mutation.type === "USER_LOGOUT") {
          Vue.$localStorage.clear(true);
          resolve(mutation);
        }

        // Initialisation des années : on enregistre l'année sélectionnée
        if (mutation.type === "INIT_YEARS" || mutation.type === "CHANGE_YEAR") {
          Vue.$localStorage.set("selectedYear", state.selectedYear);
          resolve(mutation);
        }
        if (mutation.type === "CHANGE_COURSE_TAG") {
          Vue.$localStorage.set("selectedCourseTag", state.selectedCourseTag);
          resolve(mutation);
        }
        if (mutation.type === "CHANGE_COURSE") {
          Vue.$localStorage.set("selectedCourse", state.selectedCourse);
          resolve(mutation);
        }

        // Stats
        if (mutation.type === "GET_GLOBAL_STATS") {
          Vue.$localStorage.set("globalStats", state.globalStats);
          Vue.$localStorage.set("updateTime", state.globalStats.updateTime);
          resolve(mutation);
        }
        if (mutation.type === "GET_COURSES_TOPFLOP") {
          Vue.$localStorage.set("topFlopCourses", state.topFlopCourses);
          resolve(mutation);
        }

        if (mutation.type === "GET_COURSES") {
          Vue.$localStorage.set("courses", state.courses);
          resolve(mutation);
        }

        if (mutation.type === "GET_COURSES_TAGS") {
          Vue.$localStorage.set("coursesTags", state.coursesTags);
          resolve(mutation);
        }

        if (mutation.type === "GET_USERS") {
          Vue.$localStorage.set("users", state.users);
          resolve(mutation);
        }

        if (mutation.type === "GET_QIS") {
          Vue.$localStorage.set("qis", state.qis);
          resolve(mutation);
        }

        if (mutation.type === "GET_TIMELOGS") {
          Vue.$localStorage.set("timelogs", state.timelogs);
          resolve(mutation);
        }

        // Si erreur : reject (mutation);
      });
    },

    // LOGIN / TOKEN

    // Authentification
    AUTH_REQUEST({ commit, dispatch }, loginData) {
      return new Promise((resolve, reject) => {
        // API call to authenticate user
        HTTP.post("/jwt-auth/v1/token", loginData)
          .then(function(response) {
            //console.log(response.data);

            const acceptedRoles = ["administrator", "admin_cefa_hge"];

            //has sufficient roles ?
            if (response.data.roles === undefined) {
              dispatch("USER_LOGOUT");
              reject(
                "Un problème est survenu lors de la récupération des roles, merci de réessayer."
              );
            } else if (
              isEmpty(intersection(response.data.roles, acceptedRoles))
            ) {
              dispatch("USER_LOGOUT");
              reject(
                "Ce compte n'est pas autorisé à accéder à cette plateforme."
              );
            } else {
              commit("USER_LOGIN", response.data); // update status (mutation)
              resolve(response.data);
            }
          })
          .catch(function(error) {
            dispatch("USER_LOGOUT");
            reject(
              "Erreur d'authentification : merci de vérifier votre adresse email et votre mot de passe",
              error
            );
          });
      });
    },

    // Persistent auth
    PERSISTENT_USER: ({ commit, state }) => {
      if (null !== state.userData) commit("USER_LOGIN", state.userData);
    },

    // Dés-authentification
    USER_LOGOUT: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit("USER_LOGOUT");
        resolve();
      });
    },

    // GET Stats

    GET_GLOBAL_STATS: ({ commit, dispatch }, year) => {
      // Avant de renvoyer la data, vérifier si c'est en local storage ?
      // Non pour que ce soit plus simple et ne pas gérer la purge du local storage
      return new Promise((resolve, reject) => {
        HTTP.get(`/kstats/v1/stats/${year}`)
          .then(function(response) {
            var updateTime = new Date(response.data.updated_on);
            response.data.updateTime = `${updateTime.toLocaleDateString(
              "fr-FR"
            )} à ${updateTime.toLocaleTimeString("fr-FR")}`;
            commit("GET_GLOBAL_STATS", response.data);
            resolve(response.data);
          })
          .catch(function(error) {
            if (error?.response?.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject("Pas de statistiques pour cette année !", error);
            }
          });
      });
    },

    GET_COURSES: ({ commit, dispatch }, args) => {
      const year = undefined !== args.year ? args.year : state.selectedYear;
      const filters = undefined !== args.filters ? args.filters : "";

      return new Promise((resolve, reject) => {
        HTTP.get(`/kstats/v1/stats/${year}/courses${filters}`)
          .then(function(response) {
            if (filters.includes("topflop")) {
              commit("GET_COURSES_TOPFLOP", response.data);
            } else {
              commit("GET_COURSES", response.data);
            }
            resolve(response.data);
          })
          .catch(function(error) {
            if (error?.response?.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject(
                "Une erreur est survenue dans la récupération des sessions.",
                error
              );
            }
          });
      });
    },

    GET_COURSES_TAGS: ({ commit, dispatch }, year) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`/kstats/v1/stats/${year}/courses_tags`)
          .then(function(response) {
            commit("GET_COURSES_TAGS", response.data);
            resolve(response.data);
          })
          .catch(function(error) {
            if (error.response.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject(
                "Une erreur est survenue dans la récupération des actions DPC.",
                error
              );
            }
          });
      });
    },

    GET_USERS: ({ commit, dispatch }, args) => {
      const year = undefined !== args.year ? args.year : state.selectedYear;
      const filters = undefined !== args.filters ? args.filters : "";

      return new Promise((resolve, reject) => {
        HTTP.get(`/kstats/v1/stats/${year}/users${filters}`)
          .then(function(response) {
            commit("GET_USERS", response.data);
            resolve(response.data);
          })
          .catch(function(error) {
            if (error.response.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject(
                "Une erreur est survenue dans la récupération des utilisateurs.",
                error
              );
            }
          });
      });
    },

    GET_QIS: ({ commit, dispatch }, args) => {
      const year = undefined !== args.year ? args.year : state.selectedYear;
      const filters = undefined !== args.filters ? args.filters : "";

      return new Promise((resolve, reject) => {
        HTTP.get(`/kstats/v1/stats/${year}/qis${filters}`)
          .then(function(response) {
            commit("GET_QIS", response.data);
            resolve(response.data);
          })
          .catch(function(error) {
            if (error.response.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject(
                "Une erreur est survenue dans la récupération des utilisateurs.",
                error
              );
            }
          });
      });
    },

    /**
     * Fetch timelogs
     * /wp-json/klearndash-timelogs/v1/logs/2022
     *
     * @param {commit, dispatch}
     * @param {object} args
     * @returns Promise
     */
    GET_TIMELOGS: ({ commit, dispatch }, args) => {
      const year = undefined !== args.year ? args.year : state.selectedYear;
      const filters = undefined !== args.filters ? args.filters : "";

      return new Promise((resolve, reject) => {
        HTTP.post(`/klearndash-timelogs/v1/logs/${year}${filters}`)
          .then(function(response) {
            commit("GET_TIMELOGS", response.data);
            resolve(response.data);
          })
          .catch(function(error) {
            if (error.response.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else if (error.response.status === 404) {
              // no timelogs found
              resolve(false);
            } else {
              reject(
                "Une erreur est survenue dans la récupération des entrées de temps.",
                error
              );
            }
          });
      });
    },

    /**
     * Export timelogs
     * /wp-json/klearndash-timelogs/v1/export/2022?filter[courses]=dddddd,dddddd
     *
     * @param {commit, dispatch}
     * @param {object} args
     * @returns Promise
     */
    EXPORT_TIMELOGS: ({ commit, dispatch }, args) => {
      const year = undefined !== args.year ? args.year : state.selectedYear;
      const filters = undefined !== args.filters ? args.filters : "";
      const userIDs = undefined !== args.userIDs ? args.userIDs : [];

      return new Promise((resolve, reject) => {
        HTTP.post(
          `/klearndash-timelogs/v1/export/${year}${filters}`,
          { userIDs: userIDs },
          {
            responseType: "blob" // binary (xlsx file)
          }
        )
          .then(function(response) {
            resolve(response.data);
          })
          .catch(function(error) {
            if (error?.response?.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject("Une erreur est survenue !", error);
            }
          });
      });
    },

    /**
     * Export timelogs by unit
     * /wp-json/klearndash-timelogs/v1/export/units/2022?filter[courses]=dddddd,dddddd
     *
     * @param {commit, dispatch}
     * @param {object} args
     * @returns Promise
     */
    EXPORT_TIMELOGS_UNITS: ({ commit, dispatch }, args) => {
      const year = undefined !== args.year ? args.year : state.selectedYear;
      const filters = undefined !== args.filters ? args.filters : "";
      const userIDs = undefined !== args.userIDs ? args.userIDs : [];

      return new Promise((resolve, reject) => {
        HTTP.post(
          `/klearndash-timelogs/v1/export/units/${year}${filters}`,
          { userIDs: userIDs },
          {
            responseType: "blob" // binary (xlsx file)
          }
        )
          .then(function(response) {
            resolve(response.data);
          })
          .catch(function(error) {
            if (error?.response?.status === 403) {
              dispatch("USER_LOGOUT");
              reject(
                "Authentification invalide ou expirée. Veuillez vous authentifier à nouveau.",
                error
              );
            } else {
              reject("Une erreur est survenue !", error);
            }
          });
      });
    }
  }
});
